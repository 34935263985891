import React, { useState, useEffect } from 'react';
import {Card, Container} from "react-bootstrap";
import './commitees.css';
import Papa from 'papaparse';

const Commitees = () => {
    const [tpsCsvData, setTpsCsvData] = useState([]);
    const [intCsvData, setIntCsvData] = useState([]);
    const [natCsvData, setNatCsvData] = useState([]);

    useEffect(() => {
        async function getData() {
            // Read TPS Member
            const tpsMember = await fetch("/member2.csv");
            const tpsReader = tpsMember.body.getReader();
            const tpsResult = await tpsReader.read(); // raw array
            const decoder = new TextDecoder("utf-8");
            const tpsCSV = decoder.decode(tpsResult.value); // the csv text
            const tpsResults = Papa.parse(tpsCSV, { header: false }); // object with { data, errors, meta }
            const tpsRows = tpsResults.data; // array of objects
            setTpsCsvData(tpsRows);

             // Read Int Member
            const intMember = await fetch("/International.csv");
            const intReader = intMember.body.getReader();
            const intResult = await intReader.read(); // raw array
            const intDecoder = new TextDecoder("utf-8");
            const intCSV = intDecoder.decode(intResult.value); // the csv text
            const intResults = Papa.parse(intCSV, { header: false }); // object with { data, errors, meta }
            const intRows = intResults.data; // array of objects
            setIntCsvData(intRows);

            const natMember = await fetch("/National.csv");
            const natReader = natMember.body.getReader();
            const natResult = await natReader.read(); // raw array
            const natDecoder = new TextDecoder("utf-8");
            const natCSV = natDecoder.decode(natResult.value); // the csv text
            const natResults = Papa.parse(natCSV, { header: false }); // object with { data, errors, meta }
            const natRows = natResults.data; // array of objects
            setNatCsvData(natRows);
        }
        getData();
    }, []);


     const listItemsTPS = tpsCsvData.slice(1, tpsCsvData.length-1).map((item, index) =>
          <Card.Text key={Math.random()} className="mb-2" style={{textAlign: "left"}}>
             <span className='fw-bold'>{item[0]}</span>, {item[1]}, {item[2]}
         </Card.Text>

        );

      const listItemsINT = intCsvData.slice(1, intCsvData.length-1).map((item, index) =>
          <Card.Text key={Math.random()}>
             <span className='fw-bold'>{item[0]}</span> <br/>{item[1]}, {item[2]}
         </Card.Text>

        );

       const listItemsNAT = natCsvData.slice(1, natCsvData.length-1).map((item, index) =>
          <Card.Text key={Math.random()}>
             <span className='fw-bold'>{item[0]}</span> <br/>{item[1]}, {item[2]}
         </Card.Text>

        );


    return (
        <Container>
            <h2 className="pt-4 title">COMMITTEES</h2>
            <div className="row">
                <div className="col-md-4">
                    <Card className="shadow text-center" data-aos="fade-up">
                        <center><Card.Header className="title_committee">Organizing Committee</Card.Header></center>
                        <Card.Body>
                            <Card.Title className="title_designation">Chief Patron</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Md. Habibur Rahman</span> <br/>
                                Vice-Chancellor, DUET, Bangladesh
                            </Card.Text>

                            <Card.Title className="title_designation">Patron</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Mohammad Abdur Rashid</span> <br/>
                                Pro-Vice-Chancellor, DUET, Bangladesh
                            </Card.Text>
                            <Card.Title className="title_designation">General Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Md. Obaidur Rahman</span> <br/>
                                DUET, Bangladesh

                            </Card.Text>
                            <Card.Title className="title_designation">General Co-Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Mohammad Abdur Rouf</span> <br/>
                                DUET, Bangladesh
                            </Card.Text>
                            <Card.Title className="title_designation">Organizing Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Mohammod Abul Kashem</span> <br/>
                                DUET, Bangladesh
                            </Card.Text>
                            <Card.Title className="title_designation">Organizing Co-Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>M. Shamim Kaiser</span> <br/>
                                JU, Savar, Bangladesh
                            </Card.Text>
                            <Card.Title className="title_designation">Organizing Co-Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Md. Zahidul Islam</span> <br/>
                                IU, Kushtia, Bangladesh
                            </Card.Text>

                            <Card.Title className="title_designation">Organizing Secretary</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Rafiqul Islam</span> <br/>
                                DUET, Bangladesh
                            </Card.Text>

                             <Card.Title className="title_designation">Treasurer</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Md. Shafiqul Islam</span> <br/>
                                DUET, Bangladesh
                            </Card.Text>
                            {/*<Card.Title className="title_designation">Technical Program Chair</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Fazlul Hasan Siddiqui</span> <br/>*/}
                            {/*    Professor, Dept. of CSE, DUET*/}
                            {/*</Card.Text>*/}

                        </Card.Body>
                        <center><Card.Header className="title_committee">Finance and Venue Management Committee</Card.Header></center>
                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Md. Shafiqul Islam</span> <br/>
                                DUET, Bangladesh

                            </Card.Text>
                             <Card.Text>
                                <span className='fw-bold'>Khawja Imran Masud</span> <br/>
                                DUET, Bangladesh

                            </Card.Text>

                        </Card.Body>
                        <center><Card.Header className="title_committee">Publication & Conference Management
                            Committee</Card.Header></center>

                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Rafiqul Islam</span> <br/>
                                 DUET, Bangladesh

                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Muhammad Aminur Rahaman</span> <br/>
                                Green University, Bangladesh
                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Sajeeb Saha</span> <br/>
                                JnU, Bangladesh
                            </Card.Text>

                            <Card.Text>
                                <span className='fw-bold'>Khaleda Akther Papry</span> <br/>
                                DUET, Bangladesh
                            </Card.Text>


                        </Card.Body>

                        <center><Card.Header className="title_committee">Design and Awards Committee</Card.Header>
                        </center>

                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Momotaz Begum</span> <br/>
                                 DUET, Bangladesh

                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Sumaya Khatun</span> <br/>
                                  DUET, Bangladesh
                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Sohel Rana</span> <br/>
                                  DUET, Bangladesh
                            </Card.Text>
                        </Card.Body>
                        <center><Card.Header className="title_committee">Food and Refreshment Committee</Card.Header>
                        </center>
                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Md. Jakirul Islam</span><br/>
                                  DUET, Bangladesh

                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Md. Abu Bakkar Siddique</span><br/>
                                  DUET, Bangladesh
                            </Card.Text>

                        </Card.Body>


                        <center><Card.Header className="title_committee">Registration and Conference Kit Committee</Card.Header>
                        </center>

                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Sumaya Kazary</span> <br/>
                                 DUET, Bangladesh

                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Md. Mortuza Hossain</span> <br/>
                                  DUET, Bangladesh
                            </Card.Text>
                             <Card.Text>
                                <span className='fw-bold'>Md. Litan Islam</span><br/>
                                  DUET, Bangladesh

                            </Card.Text>
                        </Card.Body>
                        <center><Card.Header className="title_committee">Transport and Accommodation Committee</Card.Header>
                        </center>

                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Amran Hossain</span> <br/>
                                 DUET, Bangladesh

                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Md. Mahbub Hasan</span> <br/>
                                  DUET, Bangladesh
                            </Card.Text>
                        </Card.Body>
                        <center><Card.Header className="title_committee">Media and Publicity Committee</Card.Header>
                        </center>

                        <Card.Body>
                            <Card.Text>
                                <span className='fw-bold'>Khawja Imran Masud</span> <br/>
                                 DUET, Bangladesh

                            </Card.Text>
                             <Card.Text>
                                <span className='fw-bold'>Dr. Umme Fawzia Rahim</span> <br/>
                                  DUET, Bangladesh
                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Md. Rajibul Islam</span> <br/>
                                  DUET, Bangladesh
                            </Card.Text>


                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="shadow text-center" data-aos="fade-up">
                        <center><Card.Header className="title_committee">Technical Program Committee</Card.Header>
                        </center>
                        <Card.Body>
                            <Card.Title className="title_designation">Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Fazlul Hasan Siddiqui</span> <br/>
                                DUET, Bangladesh
                            </Card.Text>

                            <Card.Title className="title_designation">Co-Chair</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Mohammad Shamsul Arefin</span> <br/>
                               CUET, Bangladesh
                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>Md. Al Mamun </span> <br/>
                                RUET, Bangladesh
                            </Card.Text>
                            <Card.Text>
                                <span className='fw-bold'>K. M. Azharul Hasan</span> <br/>
                                KUET, Bangladesh
                            </Card.Text>

                            {/*<Card.Title className="title_track">Track-01 (Computing & Software Engineering)</Card.Title>*/}
                            {/*<Card.Title className="title_designation"> Chairs</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Mohammad Shahin Uddin</span> <br/>*/}
                            {/*    ICT Department, MBSTU, Bangladesh.*/}
                            {/*</Card.Text>*/}
                            {/*<Card.Title className="title_designation">Secretaries</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Jakirul Islam</span> <br/>*/}
                            {/*    DUET, Bangladesh.*/}
                            {/*</Card.Text>*/}

                            {/*<Card.Title className="title_track">Track-02 (Networking & IoT)</Card.Title>*/}
                            {/*<Card.Title className="title_designation"> Chairs</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'> M  Shamim Kaiser</span> <br/>JU,Savar, Bangladesh.*/}

                            {/*</Card.Text>*/}
                            {/*<Card.Title className="title_designation">Secretaries</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Waliur Rahman Mia</span> <br/> DUET, Bangladesh.*/}

                            {/*</Card.Text>*/}
                            {/*<Card.Title className="title_track">Track-03 ( Artificial Intelligence & Machine*/}
                            {/*    Learning)</Card.Title>*/}
                            {/*<Card.Title className="title_designation"> Chairs</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Mohammad Shamsul Arefin</span> <br/>CUET, Bangladesh.*/}

                            {/*</Card.Text>*/}
                            {/*<Card.Title className="title_designation">Secretaries</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Momotaz Begum</span> <br/> DUET, Bangladesh.*/}

                            {/*</Card.Text>*/}

                            {/*<Card.Title className="title_track">Track-04 (Signal, Image & Computer Vision)</Card.Title>*/}
                            {/*<Card.Title className="title_designation"> Chairs</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Al Mamun</span> <br/> RUET, Bangladesh.*/}

                            {/*</Card.Text>*/}
                            {/*<Card.Title className="title_designation">Secretaries</Card.Title>*/}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Shafiqul Islam</span> <br/> DUET, Bangladesh.*/}

                            {/*</Card.Text>*/}


                            <Card.Title className="title_designation">Members</Card.Title>

                            {listItemsTPS}



                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="shadow text-center" data-aos="fade-up">
                        <center><Card.Header className="title_committee">International Advisory Committee</Card.Header>
                        </center>
                        <Card.Body>

                            {listItemsINT}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Hiroshi Mineo</span> <br/>Shizuoka University, Japan*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Choong Seon Hong</span> <br/>Kyung Hee University, South Korea*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>M Ali Akber Dewan</span> <br/>Athabasca University, Canada*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Xiaodong Li</span> <br/>RMIT, Australia*/}
                        {/*    </Card.Text>*/}
                        {/*      <Card.Text>*/}
                        {/*        <span className='fw-bold'>Eui-Nam Huh</span> <br/>Kyung Hee University, South Korea*/}
                        {/*    </Card.Text>*/}
                        {/*     <Card.Text>*/}
                        {/*        <span className='fw-bold'>M. Julius Hossain</span> <br/>University of Southampton, UK*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Mohammad Mehedi Hassan</span> <br/>King Saud University,*/}
                        {/*        Saudi Arabia*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Enamul Hoque Prince</span> <br/>York University, Canada*/}
                        {/*    </Card.Text>*/}

                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Abu Barkat Ullah</span> <br/> Canberra Institute of*/}
                        {/*        Technology, Australia*/}
                        {/*    </Card.Text>*/}

                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>John Yearwood</span> <br/>Deakin University, Australia*/}

                        {/*    </Card.Text>*/}


                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Mohiuddin Ahmed</span> <br/>ECU, Australia*/}

                        {/*    </Card.Text>*/}
                        {/*<Card.Text>*/}
                        {/*        <span className='fw-bold'>Muzammil Jusoh</span> <br/> UniMAP, Malaysia*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Muhammad Mostafa Monowar</span> <br/> King Abdul Aziz University, Saudi Arabia*/}
                        {/*    </Card.Text>*/}
                        {/*    <Card.Text>*/}
                        {/*        <span className='fw-bold'>Md Atiqur Rahman Ahad</span> <br/> University of East London, UK*/}
                        {/*    </Card.Text>*/}


                        </Card.Body>

                        <center><Card.Header className="title_committee">National Advisory Committee</Card.Header>
                        </center>
                        <Card.Body>
                            {listItemsNAT}
                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Mohammad Kaykobad</span> <br/>BRAC University, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Hafiz Md. Hasan Babu</span> <br/>DU, Bangladesh*/}
                            {/*</Card.Text>*/}
                            {/* <Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Abdur Razzaque</span> <br/>DU, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/*  <Card.Text>*/}
                            {/*    <span className='fw-bold'>Mohammad Shorif Uddin</span> <br/>JU, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>MMA Hashem</span> <br/>KUET, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/* <Card.Text>*/}
                            {/*    <span className='fw-bold'>M. Sohel Rahman</span> <br/>BUET, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Nasim Akhtar</span> <br/>CSTU, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/*  <Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Mahbubur Rahman</span> <br/>MIST, Bangladesh*/}
                            {/*</Card.Text>*/}
                            {/* <Card.Text>*/}
                            {/*    <span className='fw-bold'>Lafifa Jamal</span> <br/>DU, Bangladesh*/}
                            {/*</Card.Text>*/}

                            {/*<Card.Text>*/}
                            {/*    <span className='fw-bold'>Md. Nazrul Islam Mondal</span> <br/>RUET, Bangladesh*/}
                            {/*</Card.Text>*/}

                        </Card.Body>
                    </Card>
                </div>

            </div>
        </Container>
    );

}

export default Commitees;