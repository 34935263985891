import React from "react";
import './tophader.css';
const TopHeader = () => {
  return (
      <div className="topheader">
         <div className="container"  style={{ marginTop: "10px"}}>
             <div className="items row">
                 <div className="item col-sm-6 pull-left">
                     <span className="itemKey"style={{marginRight: "10px"}}><i className="fa fa-envelope-o leftIcon"></i></span>
                     <span className="itemValue">ncim@duet.ac.bd</span>

                     <span className="itemKey" style={{marginLeft: "10px",marginRight: "10px"}}> <i className="fa fa-phone leftIcon"></i> </span>
                     <span className="itemValue">+8801930706863</span>
                     <span className="itemKey" style={{marginLeft: "10px",marginRight: "10px"}}> <i className="fa fa-phone leftIcon"></i> </span>
                     <span className="itemValue">+8801911350163</span>
                 </div>
                 <div className="itemRight  col-sm-6">
                     <span className="icon"> <a href="https://cse.duet.ac.bd/" target="_blank" rel="noopener noreferrer"><i className="fa fa-globe"> </i> </a> </span>
                 <span className="icon"> <a href="https://www.facebook.com/duetcseofficial" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-f"> </i> </a> </span>
                 </div>
             </div>
            
         </div>
      </div>
  );
};

export default TopHeader;
