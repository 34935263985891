import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {verifyPayment} from "shurjopay-js";
import axios from "axios";
import config from "../../config";
import SpinnerCom from "../../components/Spinner/Spinner";
import ReactToPrint from "react-to-print";
import ncim from "../../components/assets/img/NCIMlogo.png";
import dateFormat from "dateformat";
import {tk_lists} from "../../components/data/data";
import sign from "../../components/assets/img/signature.jpg";
import {Button, Col, Form, Row} from "react-bootstrap";

const Invoice = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(null);
    const [tracking_no, setTracking_no] = useState('')


    const componentRef = useRef();

    const handleChange = (e) => {
        setTracking_no(e.target.value);

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataSent = new FormData();
        formDataSent.append('tracking_no', tracking_no);
        const response = await axios.post(`${config.apiUrlLaravel}/registration/tracking`, formDataSent);

        // console.log(response);

        if (response.data.info === 'success') {
            const newData = response.data.data;
            setData(newData);
            const category = response.data.data.payment_category;
            const paymentData = tk_lists.find(item => item.name === category);
            setPayment(paymentData);
            setLoading(false);

        } else {
            alert('Not Found Info');
            setLoading(false);
            window.location.reload();
        }
    }

    // console.log(tracking_no);
    //
    // console.log(data);
    //
    // console.log(payment);

    const invoiceInf = data !== null && (
        <div className="row">
            <div className="col-md-10 mx-auto">
                <div className="card">
                    <div className="card-body" ref={componentRef}>
                        <div className="container mb-5 mt-3">
                            <div className="row d-flex align-items-baseline">
                                <div className="col-xl-9">
                                    <p style={{color: '#7e8d9f', fontSize: '20px'}}>Payment
                                        Detail <strong></strong></p>
                                </div>
                                <div className="col-xl-3 float-end">

                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="btn btn-light text-capitalize border-0"
                                                    data-mdb-ripple-color="dark" type='button'><i
                                                className="fa fa-print text-primary"></i> Print
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle="NCIM Payment"
                                    />
                                </div>
                                <hr/>
                            </div>

                            <div className="container invoice">

                                <div className="col-md-12">
                                    <div className="text-center">
                                        <i className="fa fa-mdb fa-4x ms-0" style={{color: '#5d9fc5'}}></i>
                                        <img src={ncim} alt=""/>
                                        <p className="pt-0">International Conference on Next-Generation
                                            Computing, IoT and Machine Learning (NCIM 2023)</p>

                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-xl-7 ">
                                        <ul className="list-unstyled ml-2">
                                            <li className="text-muted">To: <span
                                                style={{color: '#5d9fc5'}}>{data.salutation}{' ' + data.name}</span>
                                            </li>
                                            <li className="text-muted">Reg. Type: {data.registration_type}</li>
                                            <li className="text-muted">Address: {`${data.region_road}, ${data.city}, ${data.postal_code}, ${data.country}`}</li>
                                            <li className="text-muted"><i
                                                className="fa fa-envelope"></i> {data.email}</li>
                                            <li className="text-muted"><i
                                                className="fa fa-phone"></i> {data.mobile}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-5">
                                        <ul className="list-unstyled">
                                            <li className="text-muted"><i className="fa fa-circle ml-1"
                                                                          style={{color: '#84B0CA'}}></i>
                                                <span
                                                    className="fw-bold"> Tracking No:</span> {data.tracking_no}
                                            </li>
                                            <li className="text-muted"><i className="fa fa-circle ml-1"
                                                                          style={{color: '#84B0CA'}}></i>
                                                <span
                                                    className="fw-bold"> Category:</span> {data.payment_category}
                                            </li>
                                            <li className="text-muted"><i className="fa fa-circle"
                                                                          style={{color: '#84B0CA'}}></i>
                                                <span
                                                    className="fw-bold"> Creation Date: </span>{dateFormat(data.payment_date, 'mediumDate')}
                                            </li>
                                            <li className="text-muted"><i className="fa fa-circle"
                                                                          style={{color: '#84B0CA'}}></i>
                                                <span
                                                    className="me-1 fw-bold"> Status:</span><span
                                                    className="badge bg-warning text-black fw-bold">{data.payment_status === 'Yes' ? 'Paid' : "Unpaid"}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {data.registration_type === "AUTHOR" ? (
                                    <div className="row my-2 mx-1 justify-content-center">
                                        <table className="table table-striped table-borderless">
                                            <thead style={{backgroundColor: '#84B0CA'}} className="text-white">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Paper Id</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.papers.map((cart, index) => (
                                                cart.value !== '' ?
                                                    <tr scope="row" key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{index === 0 ? cart.tracking_paper_id : `${cart.tracking_paper_id}(40% Discount)`}</td>
                                                        <td>{index === 0 ? payment !== null ? payment.amount.toFixed(2) : '' : payment !== null ? (payment.amount * 0.6).toFixed(2) : ''} {payment !== null ? payment.short_code : ''}</td>
                                                    </tr> : ''
                                            ))}
                                            </tbody>

                                        </table>
                                    </div>
                                ) : (
                                    <div className="row my-2 mx-1 justify-content-center">
                                        <table className="table table-striped table-borderless">
                                            <thead style={{backgroundColor: '#84B0CA'}} className="text-white">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Attendee</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <tr scope="row">
                                                <td>1</td>
                                                <td>ATTENDEE</td>
                                                <td>{payment !== null ? payment.amount : ''} {payment !== null ? payment.short_code : ''}</td>
                                            </tr>

                                            </tbody>

                                        </table>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-xl-7">
                                        <p className="ms-3">Add additional notes and payment information</p>

                                    </div>
                                    <div className="col-xl-5">
                                        <ul className="list-unstyled">
                                        </ul>
                                        <p className="text-black float-start"><span
                                            className="text-black me-3"> Total Amount</span><span
                                            style={{fontSize: '20px'}}>{data.total_amount} {payment !== null ? payment.short_code : ''}</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-4">
                                        <img src={sign} width={150} height={80} alt=""/>
                                        <p className="fw-bold mb-0"> Md. Shafiqul Islam</p>
                                        <p className="mb-0"> Treasurer, NCIM 2023 </p>

                                    </div>
                                </div>
                                <hr/>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div align="center">
                <Link to="/author_reg"> Back to Home </Link>
            </div>
        </div>
    )
    return (

        <div className="container">
            <h2 className="pt-4 title">Payment Info</h2>
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
                    <Form.Label column sm={3}>Tracking No <span
                        className="text-danger">*</span></Form.Label>
                    <Col sm={9}>
                        <Form.Control type='text' name='tracking_no' value={tracking_no} placeholder="Enter Tracking No"
                                      onChange={handleChange}
                                      required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{span: 7, offset: 5}}>
                        <Button variant='primary' className="mb-3" type='submit'>
                            Submit
                        </Button>
                    </Col>
                </Form.Group>
            </Form>

            {loading ? <SpinnerCom/> : invoiceInf}
            <br/>

        </div>
    );
};

export default Invoice;