import React from 'react';
import {Container, ListGroup} from "react-bootstrap";
import config from "../../config";
import {track} from "../../components/data/data";

const CallForPaper = () => {
    return (
        <Container>
            <h2 className="pt-4 title">CALL FOR PAPER</h2>
            <div className="row">
                <div className="col-md-12">
                    <h4 data-aos="fade-up">International Conference on Next-Generation Computing, IoT and Machine Learning
                        (NCIM 2023)</h4>
                    {/*<p className="d-flex justify-content-center align-items-center text-success fw-bold" >To Be Announced (TBA)</p>*/}

                    <div className="mt-4" data-aos="fade-up">
                        <p><b>Venue: </b> Dhaka University of Engineering & Technology (DUET), Gazipur, Bangladesh</p>
                        <p><b>Date: </b> {config.confDate}</p>
                        <p><b>Conference site Link: </b><a href="https://confncim.com/"> https://confncim.com/</a></p>
                        {/*<p><b>Submissoin Link: </b><a  target="_blank" rel="noopener noreferrer" href="https://myproconf.com/conferences/ncim-2023/">https://myproconf.com/conferences/ncim-2023/</a></p>*/}
                        {/*<p><b>Intial Paper Template: </b><a*/}
                        {/*    href="https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines"*/}
                        {/*    target="_blank"*/}
                        {/*    rel="noopener noreferrer"> https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines</a>*/}
                        {/*</p>*/}
                    </div>

                    <div className="mt-5" data-aos="fade-up">
                        <h4 className="mb-3">Call For Paper</h4>
                        <p className="text-justify card_text">

                            <b> International Conference on Next-Generation Computing, IoT and Machine Learning (NCIM
                                2023) </b> is going to be organized by Department of Computer Science and Engineering of
                            Dhaka University of Engineering &amp; Technology, Gazipur, Bangladesh on June 16-17, 2023.
                            In the areas of Computing, Internet
                            of Things, Artificial intelligence, Computer Vision, and Related new technologies. NCIM 2023
                            focuses on theoretical, practical, cutting-edge applications, and research issues.
                            The conference aims to bring together researchers, academicians, industry professionals,
                            and experts from a variety of disciplines to present contemporary research findings obtained
                            through the use of next-generation technologies.
                            Researchers of the relevant
                            fields are invited to submit their original, novel, and extended unpublished works in this
                            conference. In NCIM
                            2023,  both onsite presentations and virtual participations (from abroad only) will be allowed.

                        </p>

                        <ul>
                            <li><b className="animated-text"> All Accepted and presented papers will be submitted for inclusion into IEEE Xplore subject to meeting IEEE Xplore’s scope and quality requirements (Conference Id: 59001, Approval Pending)</b>.
                            </li>
                            <li>Extended version of the accepted and presented papers will be invited for submission in
                                various SCIE and SCOPUS indexed journals.
                            </li>
                        </ul>



                        <h2 className="pt-4 title">Technical Tracks and Scopes</h2>
                        <p className="text-justify card_text">

                            We are glad to inform you that our advisory panel, technical program committee, and
                            organizing
                            committee at NCIM 2023 include prominent scholars from both home and abroad. NCIM 2023 will
                            feature prominent keynote speakers, invited speakers, and resource persons. We invite
                            academic members
                            and researchers from all around the world to participate in this fantastic international
                            event. Papers
                            presenting original research are being sought within following tracks, but not limited to:

                        </p>
                        <ul>
                            <li><b>Track 1: Computing and Software Engineering</b>
                                <ol>
                                     {track.track1.map((item)=>(
                                         <li key={Math.random()}>{item}</li>
                                    ))}

                                </ol>
                            </li>

                            <li><b>Track 2: Networking and IoT</b>
                                <ol>
                                     {track.track2.map((item)=>(
                                         <li key={Math.random()}>{item}</li>
                                    ))}

                                </ol>

                            </li>
                            <li><b>Track 3: Artificial Intelligence and Machine
                                Learning</b>
                                <ol>
                                     {track.track3.map((item)=>(
                                         <li key={Math.random()}>{item}</li>
                                    ))}

                                </ol>

                            </li>
                            <li><b>Track 4: Signal, Image and Computer Vision</b>
                                <ol>
                                    {track.track4.map((item)=>(
                                         <li key={Math.random()}>{item}</li>
                                    ))}

                                </ol>
                            </li>
                        </ul>

                    </div>


                </div>
            </div>
        </Container>
    )
}

export default CallForPaper;