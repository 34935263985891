import React, {useState, useEffect} from 'react';
import {Form, Button, Card, Row, Col, Modal} from "react-bootstrap";
import axios from "axios";
import {papers, tk_lists} from "../../components/data/data";
import config from "../../config";
import SpinnerCom from "../../components/Spinner/Spinner";



const Poster = () => {
    const [formData, setFormData] = useState({
        tracking_no: '',
        paper_title: '',
        name: '',
        mobile: '',
        email: '',
        paper_id: '',
        poster_design: null,
        full_paper: null,
    });
    const {
        tracking_no, paper_title, name, mobile, email, paper_id, poster_design,
    } = formData;


    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [posterData, setPosterData] = useState([]);


    const handleChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});

        if (e.target.files) {
            setFormData({...formData, [e.target.name]: e.target.files[0]});
        }
    };



     const getPosterData=async ()=>{
        const response = await axios.get(`${config.apiUrlLaravel}/poster`);
        setPosterData(response.data.papers)
    }

     useEffect(() => {
        getPosterData();
    }, []);

    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        let errors = {};

        if (!formData.tracking_no) {
            errors.tracking_no = 'Tracking No type is required';
        }

        if (!formData.paper_title) {
            errors.paper_title = 'Paper title is required';
        }

        if (!formData.name) {
            errors.name = 'Abstract is required';
        }

        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Invalid email address';
        }

        if (!formData.mobile) {
            errors.mobile = 'Mobile number is required';
        }

        if (!formData.poster_design) {
            errors.poster_design = 'Please Upload is Poster';
        }


        if (poster_design) {
            const allowedTypes = ["application/pdf"];
            if (!allowedTypes.includes(poster_design.type)) {
                errors.poster_design = 'Please select a valid file type pdf.';
            }
        }


        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const closeModal = () => {
    setShowModal(false);
  };

    const handleSubmit = async e => {
         window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: Adds smooth scrolling effect
            });
        e.preventDefault();
        setLoading(true);

        const formDataSent = new FormData();

        for (const key in formData) {
            formDataSent.append(key, formData[key]);
        }

        Object.entries(formData).forEach(([key, item]) => {
            formDataSent.append(key, item)
        });

        console.log(formDataSent);

        if (validateForm()) {
              const response = await axios.post(`${config.apiUrlLaravel}/poster_info`, formDataSent,
                  {
                      headers: {
                                'Content-Type': 'multipart/form-data',
                                },
                  }
                  );

            console.log(response);

              if(response.data.info==="success"){
                  setLoading(false);
                  setShowModal(true);
                  console.log(response);
                  setFormData({
                      tracking_no: '',
                      paper_title: '',
                      name:'',
                      email: '',
                      paper_id: '',
                      mobile: '',
                      poster_design: null,
                  });
              }else{
                   setLoading(false);
                   alert("Something Wrong");
              }
        }
    };

    return (

        <div className="container">
            <h2 className="pt-4 title">POSTER REGISTRATION</h2>
            {loading? <SpinnerCom />:
                <div className="row">
                <div className="col-md-8 mx-auto">
                    <Card className="shadow" data-aos="fade-up">
                        <Card.Header>Registration Form</Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                                <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
                                    <Form.Label column sm={3}>Tracking No <span
                                        className="text-danger">*</span></Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type='text' name='tracking_no' value={tracking_no}
                                                      placeholder="Enter Tracking No"
                                                      onChange={handleChange}
                                                      required/>
                                        {formErrors.tracking_no &&
                                            <span className="error">{formErrors.tracking_no}</span>}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
                                    <Form.Label column sm={3}>Paper Title <span
                                        className="text-danger">*</span></Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type='text' name='paper_title' value={paper_title}
                                                      placeholder="Enter Paper Title"
                                                      onChange={handleChange}
                                                      required/>
                                        {formErrors.paper_title &&
                                            <span className="error">{formErrors.paper_title}</span>}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
                                    <Form.Label column sm={3}>Name<span
                                        className="text-danger">*</span></Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type='text' name='name' value={name}
                                                      placeholder="Enter Name"
                                                      onChange={handleChange}
                                                      required/>
                                        {formErrors.name &&
                                            <span className="error">{formErrors.name}</span>}
                                    </Col>

                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail" className="mb-2">
                                    <Form.Label column sm={3}>Email<span className="text-danger">*</span></Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type='email' name='email' placeholder="Enter your email"
                                                      value={email} onChange={handleChange}
                                                      required/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalPhone" className="mb-2">
                                    <Form.Label column sm={3}>Mobile<span className="text-danger">*</span></Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type='text' placeholder="Enter your mobile" name='mobile'
                                                      value={mobile} onChange={handleChange}
                                                      required/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
                                    <Form.Label column sm={3}>Paper Id</Form.Label>
                                    <Col sm={9}>
                                        <Form.Select name="paper_id" value={paper_id} required onChange={handleChange}>
                                            <option value="">--Select--</option>
                                            { posterData.map((item) => (
                                            <option value={item.acceptance_paper_id} key={item.acceptance_paper_id}>
                                                {item.acceptance_paper_id}
                                            </option>
                                            ))}

                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId='formFile' className="mb-2">
                                    <Form.Label column sm={3}>Poster Design (in PDF)</Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type='file' name="poster_design"
                                                      onChange={handleChange} required/>
                                        <span>(Upload PDF)</span>
                                        {formErrors.poster_design &&
                                            <span className="text-danger">{formErrors.poster_design}</span>}
                                    </Col>
                                </Form.Group>
                                

                                <Form.Group as={Row}>
                                    <Col sm={{span: 7, offset: 5}}>
                                        <Button variant='primary' type='submit'>
                                            Submit
                                        </Button>
                                    </Col>
                                </Form.Group>

                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div> }

            <Modal show={showModal} onHide={closeModal}
                   size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your successful Submit Poster Paper.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}


export default Poster;