import React,{useState} from "react";
import { Container, Nav, Navbar,NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import duet_logo from "../assets/img/duet-logo.png";
import ncim_logo from "../assets/img/NCIMlogo.png";
import { NavLink } from 'react-router-dom';
import './header.css';

const Header = () => {

  const [showOther, setShowOther] = useState(false);

   const showDropdownOther = (e) => {
        setShowOther(!showOther);
    }
    const hideDropdownOther = e => {
        setShowOther(false);
    }

  const handleCollapse = () => {

    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };
  
  return (
      <Navbar
      className="header_manu"
      bg="light"
      variant="light"
      expand="lg"
      sticky="top"
    >
      <Container>
        <LinkContainer to="/" exact onClick={()=>handleCollapse()} >
          <Navbar.Brand active="false">
            {/*<img*/}
            {/*  src={duet_logo}*/}
            {/*  className="img-fluid cseLogo"*/}
            {/*  alt="This is logo"*/}
            {/*/>*/}
            {/* <img*/}
            {/* style={{marginLeft: "10px"}}*/}
            {/*  src={event_logo}*/}
            {/*  className="img-fluid cseLogo"*/}
            {/*  alt="This is logo"*/}
            {/*/>*/}
             <img
              src={ncim_logo}
              className="img-fluid"
              alt="This is logo"
            />
         {/*<span className="name">NCIM 2023</span>*/}
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/" exact onClick={()=>handleCollapse()} >
              <Nav.Link className="links">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/speakers" exact onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Speakers</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/committees" onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Committees</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/call_for_paper" onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Call for Paper</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/registration" onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Registration</Nav.Link>
            </LinkContainer>
            {/*<LinkContainer to="/schedule">*/}
            {/*  <Nav.Link className="links">Schedule</Nav.Link>*/}
            {/*</LinkContainer>*/}
             <LinkContainer to="/important_dates" onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Important Dates </Nav.Link>
            </LinkContainer>

             <LinkContainer to="/schedule"  onClick={()=>handleCollapse()}>
              <Nav.Link   className="links ">Schedule</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/submission" onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Submission</Nav.Link>
            </LinkContainer>
            {/*<LinkContainer to="/sponsors">*/}
            {/*  <Nav.Link className="links">Sponsors</Nav.Link>*/}
            {/*</LinkContainer>*/}
             <LinkContainer to="/contact" onClick={()=>handleCollapse()}>
              <Nav.Link className="links">Contact</Nav.Link>
            </LinkContainer>
            <NavDropdown
                style={{fontSize: "14px", fontWeight: "500", color: "red"}}
                title="Others"
                id="about-nav-dropdown"
                align="end"
                show={showOther}
                onMouseEnter={showDropdownOther}
                onMouseLeave={hideDropdownOther}
            >
              {/*<LinkContainer  to="/about" exact onClick={()=>handleCollapse()}>*/}
              {/*  <NavDropdown.Item>About DUET</NavDropdown.Item>*/}
              {/*</LinkContainer>*/}
              <LinkContainer to="/accommodation" onClick={()=>handleCollapse()}>
                <NavDropdown.Item>Accommodation</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/tourists" onClick={()=>handleCollapse()}>
                <NavDropdown.Item>Tourists Attractions</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>


          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
  );
};

export default Header;
