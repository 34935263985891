import React from 'react';
import {Card, Container} from "react-bootstrap";
import './about.css';
import chuti from "../../components/assets/images/home7.jpg";
import la from "../../components/assets/images/la.jpg";
import sarah from "../../components/assets/images/sara.jpg";
import bhaowal from "../../components/assets/images/bhaowal.jpg";

const Accommodation = () => {
    return (
        <Container>
            <h2 className="pt-4 title">{'Accommodation'.toUpperCase()}</h2>

           <div className="card">
                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={la} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title">Le Meridien Dhaka</h5>
                             <p>Details: <a href="https://www.marriott.com/en-us/hotels/dacmd-le-meridien-dhaka/overview/" target="_blank">Click</a></p>
                            {/*<p className="card-text text-justify card_text">Set in Dhaka with a skyline infinity pool on the 16th floor, within 3.7 km of Biman Bangladesh Airlines and 9 km of North South University, Le Meridien Dhaka features free WiFi. A hot tub is available for guests.</p>*/}
{/*                            <p>A continental, buffet or Full English/Irish breakfast can be enjoyed at the property.*/}

{/*Guests can make use of the fitness centre and swimming pool.*/}

{/*For any tips on how to get around or what to do in the area, guests can ask at the reception.</p>*/}
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={chuti} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Chuti Resort, Gazipur</h5>
                            <p>Details: <a href="https://www.chutiresort.com/" target="_blank">Click</a></p>

{/*                            <p className="card-text text-justify card_text">Chuti Resort, eighteen kilometers from Dhaka Airport which is easily accessible by road and it takes only one hour and thirty minutes to reach, lies in a rural environment of Sukundi village of Gazipur on 54 bigha of land which is near to the heritage site “Bhawal Rajbari” and “Bhawal National Park”. Breathtaking views of surrounding lake water and wildlife in amazing natural beauty of land covered with dense green blanket of flora makes anyone feel like enjoying the country’s natural heritage.*/}

{/*In this peaceful and tranquil environment, you can have the glimpse of wildlife, firefly processions at night and according to the rules of the resort, during moonlight no light is lit in outside. It is one of the best holiday destination to enjoy the full moon and rain in the rainy season; also to breathe in the fresh air.</p>*/}
                        </div>
                    </div>
                </div>

               <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={sarah} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Sarah Resort Gazipur</h5>
                            <p>Details: <a href="https://www.sarahresort.com/" target="_blank">Click</a></p>

{/*                            <p className="card-text text-justify card_text">Chuti Resort, eighteen kilometers from Dhaka Airport which is easily accessible by road and it takes only one hour and thirty minutes to reach, lies in a rural environment of Sukundi village of Gazipur on 54 bigha of land which is near to the heritage site “Bhawal Rajbari” and “Bhawal National Park”. Breathtaking views of surrounding lake water and wildlife in amazing natural beauty of land covered with dense green blanket of flora makes anyone feel like enjoying the country’s natural heritage.*/}

{/*In this peaceful and tranquil environment, you can have the glimpse of wildlife, firefly processions at night and according to the rules of the resort, during moonlight no light is lit in outside. It is one of the best holiday destination to enjoy the full moon and rain in the rainy season; also to breathe in the fresh air.</p>*/}
                        </div>
                    </div>
                </div>

               <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={bhaowal} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Bhawal Resort & Spa, Gazipur</h5>
                            <p>Details: <a href="https://www.bhawalresort.com/" target="_blank">Click</a></p>

{/*                            <p className="card-text text-justify card_text">Chuti Resort, eighteen kilometers from Dhaka Airport which is easily accessible by road and it takes only one hour and thirty minutes to reach, lies in a rural environment of Sukundi village of Gazipur on 54 bigha of land which is near to the heritage site “Bhawal Rajbari” and “Bhawal National Park”. Breathtaking views of surrounding lake water and wildlife in amazing natural beauty of land covered with dense green blanket of flora makes anyone feel like enjoying the country’s natural heritage.*/}

{/*In this peaceful and tranquil environment, you can have the glimpse of wildlife, firefly processions at night and according to the rules of the resort, during moonlight no light is lit in outside. It is one of the best holiday destination to enjoy the full moon and rain in the rainy season; also to breathe in the fresh air.</p>*/}
                        </div>
                    </div>
                </div>
            </div>


        </Container>
    )
}

export default Accommodation;