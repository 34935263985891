import React from 'react';
import {Card, Container} from "react-bootstrap";
import './about.css';
import  safari from '../../components/assets/images/safari.jpg'
import  national from '../../components/assets/images/national.jpg'
import  rajbari from '../../components/assets/images/BhawalRajbari.jpg'
import  hitect from '../../components/assets/images/hitect.jpg'
import  nohas from '../../components/assets/images/nohas.jpg'

const Tourists = () => {
    return (
        <Container>
            <h2 className="pt-4 title">{'Tourists Attractions'.toUpperCase()}</h2>
            <div className="card">
                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={safari} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title">Bangabandhu Sheikh Mujib Safari Park</h5>
                            <p>Details: <a href="http://www.safariparkgazipur.info.bd/" target="_blank">Click</a></p>
                            {/*<p className="card-text text-justify card_text">Bangabandhu Sheikh Mujib Safari Park is a wildlife park located in Gazipur, Bangladesh.*/}
                            {/*    The*/}
                            {/*    park covers an area of 1,600 acres and was officially opened to the public in 2013.*/}

                            {/*    The park is named after Sheikh Mujibur Rahman, the founding father of Bangladesh, who is*/}
                            {/*    also known as Bangabandhu. The park features a variety of animals, including tigers,*/}
                            {/*    lions,*/}
                            {/*    elephants, deer, zebras, giraffes, and many other species.*/}

                            {/*    One of the unique features of the park is the safari ride, which allows visitors to get*/}
                            {/*    up*/}
                            {/*    close and personal with the animals in their natural habitat. The safari ride takes*/}
                            {/*    visitors*/}
                            {/*    through different zones of the park, each of which is home to different species of*/}
                            {/*    animals.*/}

                            {/*    The park also has a variety of other attractions, including a bird park, a butterfly*/}
                            {/*    garden,*/}
                            {/*    a fishing lake, and a picnic area. There are also several restaurants and food stalls*/}
                            {/*    within*/}
                            {/*    the park, serving a variety of local and international cuisine.</p>*/}
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={national} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Bhawal National Park, Gazipur</h5>
{/*                            <p className="card-text text-justify card_text">Bhawal National Park is a wildlife reserve located in Gazipur district of Bangladesh. It was established in 1982 and covers an area of approximately 1,600 hectares. The park is situated about 40 km north of Dhaka, the capital city of Bangladesh.*/}

{/*The park is home to a wide variety of flora and fauna, including several species of mammals, birds, reptiles, and insects. Some of the mammal species found in the park include deer, wild boars, monkeys, mongooses, and jackals. The bird species found in the park include various types of eagles, vultures, owls, and kingfishers. Reptile species found in the park include various types of snakes, lizards, and turtles.</p>*/}
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={rajbari} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Bhawal Rajbari, Gazipur</h5>
{/*                            <p className="card-text text-justify card_text">Bhawal National Park is a wildlife reserve located in Gazipur district of Bangladesh. It was established in 1982 and covers an area of approximately 1,600 hectares. The park is situated about 40 km north of Dhaka, the capital city of Bangladesh.*/}

{/*The park is home to a wide variety of flora and fauna, including several species of mammals, birds, reptiles, and insects. Some of the mammal species found in the park include deer, wild boars, monkeys, mongooses, and jackals. The bird species found in the park include various types of eagles, vultures, owls, and kingfishers. Reptile species found in the park include various types of snakes, lizards, and turtles.</p>*/}
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={hitect} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Bangabandhu Hi-Tech City</h5>
{/*                            <p className="card-text text-justify card_text">Bhawal National Park is a wildlife reserve located in Gazipur district of Bangladesh. It was established in 1982 and covers an area of approximately 1,600 hectares. The park is situated about 40 km north of Dhaka, the capital city of Bangladesh.*/}

{/*The park is home to a wide variety of flora and fauna, including several species of mammals, birds, reptiles, and insects. Some of the mammal species found in the park include deer, wild boars, monkeys, mongooses, and jackals. The bird species found in the park include various types of eagles, vultures, owls, and kingfishers. Reptile species found in the park include various types of snakes, lizards, and turtles.</p>*/}
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div className="col-md-5 mt-2">
                        <img src={nohas} className="card-img mx-auto" />
                    </div>
                    <div className="col-md-7">
                        <div className="card-body">
                            <h5 className="card-title"> Nuhash Polli</h5>
{/*                            <p className="card-text text-justify card_text">Bhawal National Park is a wildlife reserve located in Gazipur district of Bangladesh. It was established in 1982 and covers an area of approximately 1,600 hectares. The park is situated about 40 km north of Dhaka, the capital city of Bangladesh.*/}

{/*The park is home to a wide variety of flora and fauna, including several species of mammals, birds, reptiles, and insects. Some of the mammal species found in the park include deer, wild boars, monkeys, mongooses, and jackals. The bird species found in the park include various types of eagles, vultures, owls, and kingfishers. Reptile species found in the park include various types of snakes, lizards, and turtles.</p>*/}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Tourists;