import React from 'react';
import {Container} from "react-bootstrap";
import config from "../../config";

const Banner = () => {
    return (
        <div className="Section_top container-xxl">
            <div className="content">
                <h1 className="mb-4 pb-0" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">

                    INTERNATIONAL CONFERENCE ON <br/>
                   <span> NEXT-GENERATION COMPUTING,  IoT AND MACHINE LEARNING </span>
                </h1>

                <h1 className="mb-4 pb-0 text-danger" data-aos="fade-down">NCIM 2023</h1>

                <p className="mb-4 pb-0" data-aos="fade-up">
                    {config.confDate} <br/> Department of Computer Science and
                    Engineering <br/>
                    Dhaka University of Engineering & Technology (DUET), Gazipur, Bangladesh
                </p>
            </div>
        </div>
    )
}

export default Banner