import React, {useState} from "react";
import {Card, Container, Modal, Button} from "react-bootstrap";
import second from "../../components/assets/img/photo2.jpg";
import keynote2 from "../../components/assets/img/key-2.JPG";
import keynote3 from "../../components/assets/img/ken_up_3.jpg";
import keynote4 from "../../components/assets/img/key-4.jpg";
import sozo from "../../components/assets/img/sozo.jpg";
import mahafuz from "../../components/assets/img/4th.png";
import './spearkers.css';
import {Link} from "react-router-dom";


const Speakers = () => {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    return (
        <Container>
            <h2 className="pt-4 title" data-aos="fade-up">KEYNOTE / INVITED SPEAKERS</h2>

            <div className="row mb-2" data-aos="fade-up">
                <div className="col-md-4">
                    <Card className="shadow text-center pb-2 mb-2 align-items-center card_hight">
                        <Card.Img variant="top" src={keynote2} className="rounded-circle"
                                  style={{height: '200px', width: '200px', marginTop: '10px'}}/>
                        <Card.Body>
                            <Card.Title> <Link to="#" onClick={handleShow}>Jyotika Athavale </Link></Card.Title>
                            <Card.Text className="fw-bold mb-0">
                                2024 IEEE Computer Society President
                            </Card.Text>
                            <Card.Text className="fw-bold mb-1">
                                Senior Technical Leader, NVIDIA
                            </Card.Text>
                            <Card.Text className="mb-1" style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Topic
                            </Card.Text>
                            <Card.Text>
                                IEEE Computer Society 2023 Technology Predictions
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="shadow text-center mb-2 pb-2 align-items-center card_hight">
                        <Card.Img variant="top" src={second} className="rounded-circle"
                                  style={{height: '200px', width: '200px', marginTop: '10px'}}/>
                        <Card.Body>
                            <Card.Title> <Link to="#" onClick={handleShow1}>Maiga Chang, PhD </Link></Card.Title>
                            <Card.Text className="fw-bold mb-0">
                                Professor, Athabasca University, Canada
                            </Card.Text>
                            <Card.Text className="fw-bold mb-0">
                                IEEE Senior Member <br/>
                                2023 IEEE Computer Society Golden Core Member
                            </Card.Text>
                            <Card.Text className="mb-1" style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Topic
                            </Card.Text>
                            <Card.Text>
                               Using Machine Learning Tools in the Cloud: Experience Gained from the Ask4Summary research project
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="shadow text-center mb-2 pb-2 align-items-center card_hight">
                        <Card.Img variant="top" src={keynote3} className="rounded-circle"
                                  style={{height: '200px', width: '200px', marginTop: '10px'}}/>
                        <Card.Body>
                            <Card.Title> <Link to="#" onClick={handleShow2}>Rossi Setchi, PhD </Link></Card.Title>
                            <Card.Text className="fw-bold mb-0">
                                Professor, Cardiff University, UK
                            </Card.Text>
                            <Card.Text className="fw-bold mb-0">
                                IEEE Senior Member <br/>
                            </Card.Text>
                            <Card.Text className="mb-1" style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Topic
                            </Card.Text>
                            <Card.Text>
                                Human-like AI
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card className="shadow text-center mb-2 pb-2 align-items-center card_hight">
                        <Card.Img variant="top" src={mahafuz} className="rounded-circle"
                                  style={{height: '200px', width: '200px', marginTop: '10px'}}/>
                        <Card.Body>
                            <Card.Title> <Link to="#" onClick={handleShow5}>Dr. Mohammad Mahfuzul
                                Islam</Link></Card.Title>
                            <Card.Text className="fw-bold mb-0">
                                Vice Chancellor, Bangabandhu Sheikh Mujibur Rahman Digital University, Bangladesh
                            </Card.Text>
                            <Card.Text className="mb-1" style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Topic
                            </Card.Text>
                            <Card.Text>
                                Smart Bangladesh: Opportunities and Challenges
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="shadow text-center mb-2 pb-2 align-items-center card_hight">
                        <Card.Img variant="top" src={sozo} className="rounded-circle"
                                  style={{height: '200px', width: '200px', marginTop: '10px'}}/>
                        <Card.Body>
                            <Card.Title> <Link to="#" onClick={handleShow4}>Sozo Inoue, PhD </Link></Card.Title>
                            <Card.Text className="fw-bold mb-0">
                                Professor, Kyushu Institute of Technology, Japan
                            </Card.Text>
                            <Card.Text className="mb-1" style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Topic
                            </Card.Text>
                            <Card.Text>
                                Research for Care Forecasting based on Bigdata and the Deployment to the Real World
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="shadow text-center mb-2 pb-2 align-items-center card_hight">
                        <Card.Img variant="top" src={keynote4} className="rounded-circle"
                                  style={{height: '200px', width: '200px', marginTop: '10px'}}/>
                        <Card.Body>
                            <Card.Title> <Link to="#" onClick={handleShow3}>Md. Atiqur Rahman Ahad,
                                PhD </Link></Card.Title>
                            <Card.Text className="fw-bold mb-0">
                                Associate Professor, University of East London, UK
                                <br/>
                                Kyushu Institute of Technology, Japan
                            </Card.Text>
                            <Card.Text className="fw-bold mb-0">
                                IEEE Senior Member<br/>
                            </Card.Text>
                            <Card.Text className="mb-1" style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Topic
                            </Card.Text>
                            <Card.Text>
                                AI in Healthcare based on Vision, Skeleton, and IoT Sensor: Some Examples
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Biography</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-justify card_text bograpy">
                        Jyotika Athavale is the 2023 President-Elect of the IEEE Computer Society. She is an experienced
                        leader and influencer in emerging technologies and international standardization initiatives.
                        She was awarded the IEEE Computer Society Golden Core Award in 2022. Jyotika chairs the IEEE
                        P2851 Standard on Functional Safety interoperability.

                        With over 25 years of industry experience, Jyotika is currently a senior technical leader in
                        automotive functional safety at NVIDIA, and is driving capability development, safety
                        architectures and methodologies, system safety engineering activities and pathfinding for safety
                        critical markets such as autonomous driving and avionics. Prior to NVIDIA, she was Principal
                        Engineer (Director) at Intel Corporation where she led functional safety platform architectures
                        for Automotive and Avionics use cases and drove methodologies for radiation effects modeling and
                        product qualification activities.

                        Outside of IEEE, Jyotika is actively influencing several international standardization
                        initiatives on functional safety with ISO, SAE and UL. She also serves on various technical
                        conference committees, has authored patents and many technical publications in IEEE conferences
                        and journals. She received her MS degree in Electrical Engineering in 1996 from Iowa State
                        University.
                    </p>
                    <a href="https://www.computer.org/profiles/jyotika-athavale?fbclid=IwAR2p91hnPT32tISAnDZnhj8b8QDBV4zPz1zGJo_5Qta2S055XNOY6GC5TJ0"
                       className="btn btn-primary btn-sm"
                       target="_blank" rel="noopener noreferrer">Details</a>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show1} onHide={handleClose1}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Biography</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-justify card_text bograpy">
                        Dr. Maiga Chang is a Full Professor in the School of Computing and Information Systems at
                        Athabasca University, Canada. He is IEEE Senior Member. Dr. Chang has been appointed as an IEEE
                        Computer Society Distinguished Visitor for 2023 to 2025 and also received Distinguished
                        Researcher Award from Asia Pacific Society on Computers in Education (APSCE) in 2022. Dr. Chang
                        is now Chair (2018~2023) of IEEE Technical Community of Learning Technology (TCLT), Executive
                        Committee member of Asia-Pacific Society for Computers in Education (2017~2024, APSCE) and
                        Global Chinese Society for Computing in Education (2016~2025, GCSCE), and Vice President (2022~)
                        of International Association of Smart Learning Environments (IASLE). He is also editors-in-chief
                        (2019~) of Journal of Educational Technology & Society (Open Access SSCI) and has given more
                        than 135 talks and published more than 250 conference papers, journal papers, and book chapters.
                    </p>
                    <a href="https://pure.athabascau.ca/en/persons/maiga-chang?fbclid=IwAR3EQVSgZI-uXT8MWnUK8upqWiux5cL7qlngiUijH0bA5bhHfFkyRKgY_z8"
                       className="btn btn-primary btn-sm"
                       target="_blank" rel="noopener noreferrer">Details</a>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Biography</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-justify card_text bograpy">
                        Rossi Setchi is Professor in High-Value Manufacturing and the Director and Principal
                        Investigator of the Research Centre in AI, Robotics and Human-Machine Systems (IROHMS) at
                        Cardiff, which she founded in 2019. IROHMS is a collaboration of the Schools of Engineering,
                        Psychology and Computer-Science and Informatics. IROHMS currently involves over 100 academics
                        and postgraduate researchers and 35 PhD students. For many years she led ASTUTE Cardiff, a team
                        of 20+ academics and researchers helping Welsh manufacturing companies to develop more advanced
                        and sustainable engineering solutions.

                        Rossi has a distinguished track record of research in a range of areas including AI, robotics,
                        systems engineering, additive manufacturing, industrial sustainability, Cyber-Physical Systems
                        and Industry 4.0, and, in particular, has built an international reputation for excellence in
                        knowledge-driven symbolic AI, computational semantics and human-machine systems. Her edited
                        books on Sustainable Design and Manufacturing are among the top Springer publications in 2019
                        and 2018 that address important United Nations Sustainable Development Goals (SDGs).
                    </p>
                    <a href="https://profiles.cardiff.ac.uk/staff/setchi"
                       className="btn btn-primary btn-sm"
                       target="_blank" rel="noopener noreferrer">Details</a>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Biography</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-justify card_text bograpy">
                        Md Atiqur Rahman Ahad, PhD. (Senior Member, IEEE; Senior Member, OPTICA (formerly The OSA)) is
                        an Associate Professor of Artificial Intelligence & Machine Learning, and Champion, Research and
                        Innovation in the Department of Computer Science and Digital Technologies,, University of East
                        London.
                        <br/>
                        He became a Professor at the University of Dhaka (DU) in 2018, and served as a specially
                        appointed Associate Professor at Osaka University from 2018 till 2022.
                        <br/>
                        He works on AI, ML, vision, biometrics & security, IoT, & healthcare. He studied at Kyushu
                        Institute of Technology (for Ph.D.), University of New South Wales (for Masters), and DU (for
                        Masters (1st class, 2nd position) & B.Sc. (Honors) (1st class, 1st position)).
                        <br/>
                        He was awarded the prestigious UGC Gold Medal (handed by the Honorable President of Bangladesh),
                        JSPS Postdoctoral Fellowship, and ~45 awards/grants/scholarships.
                        <br/>
                        He published 11 books (more to appear soon), ~200 journals/conference papers & book chapters.
                        Ahad was invited as keynote/invited speakers ~150 times in different
                        conferences/societies/universities.
                        <br/>
                        He is an Editorial Board Member, Scientific Reports, Nature; Associate Editor, Frontiers in
                        Computer Science; Editor, Int. J. of Affective Computing; Editor-in-Chief: Int. J. of Computer
                        Vision & Signal Processing; General Chair, 10th ICIEV, 5th IVPR, 4th ABC; Workshop Chair, 17th
                        IEEE PiCom; Publication Chair, 2018 IEEE SMC; Vice Publication Co-chair & Vice Award Chair, 17th
                        World Congress of IFSA; Guest Editor in Pattern Recognition Letters; J. of Multimedia User
                        Interface; J. of Healthcare Engineering. He is a member of ACM and IAPR.
                    </p>
                    <a href="http://ahadvisionlab.com/?fbclid=IwAR1t3bUGbqmod3-Q_8ZGghqj2p-m59vHeYnJfZGEeWmBe11D9azdD-XQKSk#res"
                       className="btn btn-primary btn-sm"
                       target="_blank" rel="noopener noreferrer">Details</a>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Biography</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-justify card_text bograpy">
                        1997 Graduated from the Department of Information Engineering, Faculty of Engineering, Kyushu
                        University. In 2002, completed the doctoral program at the Graduate School of Systems and
                        Information Sciences, Kyushu University. Since 2002, he has been an assistant at the System LSI
                        Research Center of the Institute of Systems and Information Science. Since 2006, he has been an
                        associate professor at the Research and Development Office of the University Library. Since
                        2009, he has been an associate professor at the Graduate School of Engineering, Kyushu Institute
                        of Technology. From 2018, Graduate School of Life Science and Systems Engineering, and from
                        2020, Professor at the same university. Up to now. During this time, 2009-2014 special
                        researcher at the Kyushu University Library, 2011-2012 part-time lecturer at Kyushu University
                        Graduate School of Information Science and Technology, 2014 visiting researcher at the Karlsruhe
                        Institute of Technology in Germany, 2015-2016 special researcher at the Kyushu Institute of
                        Advanced Science and Technology, 2017-2019 TeamAIBOD Co., Ltd. Technical Advisor, 2017-2019
                        Visiting Researcher, RIKEN Center for Advanced Intelligence Project. 2019 Visiting Professor,
                        University of Los Andes, Colombia. 2020 - AUTOCARE CTO LLC. 2022- Director of Care XDX Center,
                        Kyushu Institute of Technology.
                        <br/>
                        He is interested in web/ubiquitous information systems, human activity recognition using
                        smartphones, and medical applications of sensor information systems. Behavior sensor big data on
                        nursing behavior and care behavior are collected and analyzed.
                        <br/>
                        Director and senior member of Information Processing Society of Japan, member of IEEE, ACM,
                        Database Society of Japan, Institute of Electronics, Information and Communication Engineers,
                        Intelligent Information Fuzzy Society of Japan, Japan Society of Medical Informatics.
                    </p>
                    <a href="https://sozolab.jp/?fbclid=IwAR09LJfYvEwZYCrzcs_MIPSQEs09OYGXsZAaWfeErmjlwhocvFK5IsnuS84"
                       className="btn btn-primary btn-sm"
                       target="_blank" rel="noopener noreferrer">Details</a>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show5} onHide={handleClose5}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Biography</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="text-justify card_text bograpy">
                        Prof. Dr. Mohammad Mahfuzul Islam received his Ph.D. degree from Gippsland School of Information
                        Technology, Monash University Australia in 2006 and his M. Sc. Engg and B. Sc. Engg (Hons)
                        degrees in Computer Science and Engineering (CSE) from Bangladesh University of Engineering and
                        Technology (BUET) in 2000 and 1997 respectively. Currently, he is serving as the Vice-Chancellor
                        of Bangabandhu Sheikh Mujibur Rahman Digital University. He was an Associate Professor at the
                        Department of CSE, BUET, and Associate Director, at the Directorate of Students' Welfare, BUET.
                        His research interests are Cellular Multimedia Communications, Wireless Sensor Networking, and
                        Network Security. He has published a significant number of research articles in national and
                        international journals. He is a member of IEEE.
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose5}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Speakers;
