import React from 'react';
import {Container} from "react-bootstrap";
import duet_logo from "../../components/assets/img/duet-logo.png";
import IEEE from "../../components/assets/img/IEEE_logo1.jpg";
import IEEE_CS_Logo from "../../components/assets/img/IEEE-CS_Logo1.jpg";
// import IEEE_BD from "../../components/assets/img/IEEE_BD1.jpg";
import IEEE_BD from "../../components/assets/img/ieee_bd2.jpg";
import ugc from "../../components/assets/img/UGC.png";
import Museum from "../../components/assets/img/Museum.png";
import ati from "../../components/assets/img/A2i.png";
import ch24 from "../../components/assets/img/24.png";
import smart from "../../components/assets/img/smart.png";
import IIT from "../../components/assets/img/IIT.png";
import ATC from "../../components/assets/img/ATC.png";
import Janakantha from "../../components/assets/img/Janakantha.png";
import shurjoPay from "../../components/assets/img/shurjoPay.png";

const Organizer = () => {
    return (
        <Container fluid className="mb-3">
            <Container className="mt-5 ">
                <div className='row'>
                    <center>
                        <div className="col-md-12 mb-4" data-aos="fade-up">
                            <h2 className="pt-4 title">ORGANIZER</h2>
                            <h4 className="m-1" style={{color: '#510878'}} >Department of Computer Science and Engineering</h4>
                            <h5>Dhaka University of Engineering & Technology, Gazipur</h5>

                        </div>
                    </center>

                    <div className="col-md-4" data-aos="fade-down">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                    <h2 className="pt-4 title">TECHNICAL CO-SPONSORS</h2>
                                </center>
                            </div>
                            <div className="col-md-6 text-center">
                                <img src={IEEE}  className="img-fluid " alt="Responsive image"/>
                            </div>
                            <div className="col-md-6 text-center">
                                <img src={IEEE_CS_Logo} className="img-fluid "   alt="Responsive image"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3" data-aos="fade-down">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                    <h2 className="pt-4 title">TECHNICAL SUPPORT</h2>
                                </center>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-8 text-center">
                                    <img src={IEEE_BD} className="img-fluid o" alt="Responsive image"/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-5" data-aos="fade-up">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                    <h2 className="pt-4 title">SPONSORS</h2>
                                </center>
                                <dir className="row justify-content-center">
                                    <div className="col-md-3 mt-2 text-center">
                                        <img src={duet_logo} className="img-fluid " width={90} height={90}  alt="Responsive image"/>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <img src={ugc} className="img-fluid "  width={90} height={90}alt="Responsive image"/>
                                    </div>
                                      <div className="col-md-3 mt-2 text-center">
                                        <img src={Museum} className="img-fluid " width={90} height={90} alt="Responsive image"/>
                                    </div>
                                     <div className="col-md-3 text-center">
                                        <img src={ati} className="img-fluid " width={90} height={90} alt="Responsive image"/>
                                    </div>

                                    {/*<div className="col-md-12 text-center">*/}
                                    {/*    <img src={duet_logo} className="img-fluid" width={90} height={90} alt="Responsive image"/>*/}
                                    {/*</div>*/}
                                </dir>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6" data-aos="fade-down">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                    <h2 className="pt-4 title"> MEDIA PARTNER</h2>
                                </center>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-4 text-center">
                                    <img src={ch24}  width={80} height={80} className="img-fluid o" alt="Responsive image"/>
                                </div>
                                 <div className="col-md-4 mt-2 text-center">
                                    <img src={Janakantha}  className="img-fluid o" alt="Responsive image"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6" data-aos="fade-down">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                    <h2 className="pt-4 title">CO-SPONSORS</h2>
                                </center>
                                <dir className="row justify-content-center">
                                    <div className="col-md-3 mt-2 text-center">
                                        <img src={smart} className="img-fluid "  height={90}  alt="Responsive image"/>
                                    </div>
                                    <div className="col-md-3 text-center">
                                        <img src={ATC} className="img-fluid mt-1 "  width={90} height={90}alt="Responsive image"/>
                                    </div>
                                      <div className="col-md-3 mt-2 text-center">
                                        <img src={IIT} className="img-fluid "  height={90} alt="Responsive image"/>
                                    </div>
                                     <div className="col-md-3 mt-2 text-center">
                                        <img src={shurjoPay} className="img-fluid "  height={90} alt="Responsive image"/>
                                    </div>

                                </dir>

                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Container>
    );
}


export default Organizer;
