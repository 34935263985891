import React from "react";
import About from "./About";
import Banner from "./Banner";
import "./home.css";
import Organizer from "./Organizer";
import Marquee from "react-fast-marquee";
import {Link} from "react-router-dom";
// import Banner from '../../components/Banner/Banner';

const Home = () => {
    return (
        <div>
            <Banner/>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-2"><h6 style={{color: 'blue'}}><strong>News and Update:</strong></h6></div>
                    <div className="col-md-10">
                        <Marquee pauseOnHover={true} gradientWidth={3}>
                            <h6 style={{color: '#7D1316', fontWeight: 'bold'}}>
                                Oral Presentation Preparation Guideline: <Link to="/submission">Click Here</Link> <i className="fa fa-circle" style={{fontSize: '12px'}} aria-hidden="true"></i>

                                Transport Schedule of NCIM 2023:  <Link to="/schedule">Click Here</Link>  <i className="fa fa-circle" style={{fontSize: '12px'}} aria-hidden="true"></i>
                                Program Schedule of NCIM 2023:  <Link to="/schedule">Click Here</Link>  <i className="fa fa-circle" style={{fontSize: '12px'}} aria-hidden="true"></i>



                                IEEE Technical Co-sponsorship for NCIM
                                2023 has been approved on 23 May, 2023 (Conference Id: 59001) <i
                                    className="fa fa-circle" style={{fontSize: '12px'}}
                                    aria-hidden="true"></i> Notification of Acceptance for NCIM 2023 has been sent <i
                                    className="fa fa-circle" style={{fontSize: '12px'}} aria-hidden="true"></i>

                            </h6>

                        </Marquee>
                    </div>
                </div>

                {/*<h5 className="text-center">  <Link to="/schedule" className="animated-text" >Program Schedule</Link>  <a href="/template/3_Paper.pdf" download>Download</a> </h5>*/}
                {/*<h5 className="text-center animated-text"> <Link to="/schedule"  className="animated-text" >Transportation Schedule</Link> <a href="/template/3_Paper.pdf" download>Download</a> </h5>*/}


                {/*<Marquee pauseOnHover={true} gradientWidth={10}>*/}
                {/*    <h5 style={{color: 'red'}} className="animated-text">Paper Submission Deadline : 10 April, 2023 (Extended)</h5>*/}
                {/*</Marquee>*/}
            </div>

            {/*<Banner />*/}
            <About/>
            <Organizer/>

        </div>
    );
};

export default Home;
