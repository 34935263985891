import React from "react";
import {Badge, Container, ListGroup} from "react-bootstrap";
import './submission.css';
import Proconf from "../../components/Proconf/Proconf";
import config from "../../config";
import {dateData} from "../../components/data/data";
import {Link} from "react-router-dom";


const Submission = () => {

    return (
        <Container>

            <div className="row">
                <div className="col-md-9 text_format">
                    <h2 className="pt-4 title">IMPORTANT DATES</h2>
                    {/*<p className="d-flex justify-content-center align-items-center text-success fw-bold" >To Be Announced (TBA)</p>*/}

                    <ListGroup as="ul" variant="flush">
                        {dateData.map((item) => (
                            <div key={Math.random()}>

                                <ListGroup.Item as="li" key={Math.random()}
                                                className="d-flex justify-content-between align-items-start"
                                                data-aos="fade-up">

                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{item.heading}</div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end">
                                        <Badge bg={item.color}
                                               className={["p-2", item.status !== 'Active' ? 'text-decoration-line-through' : ''].join(' ')}
                                               pill>
                                            {item.dateEvent}
                                        </Badge>

                                        {item.extension &&
                                            <Badge bg={item.color}
                                                   className={["p-2", "mt-2", item.heading === 'Camera-Ready Submission' ? '' : 'text-decoration-line-through'].join(' ')}
                                                   pill>
                                                {item.extension} <br/>
                                                (Extended)

                                            </Badge>
                                        }
                                        {item.extension && item.hardexten !== '' && (
                                            <Badge bg="success" className="p-2 mt-2" pill>
                                                {item.hardexten} <br/>
                                                (Hard Deadline)
                                            </Badge>)
                                        }
                                    </div>

                                </ListGroup.Item>


                            </div>
                        ))}

                    </ListGroup>
                    <h2 className="pt-4 title">Camera-Ready Paper Submission Guidelines</h2>
                    <u><h6>Step 1: Update Your Paper as per Reviewers' Comments</h6></u>
                    <ol>
                        <li><p className="mb-1">Please revise your paper according to reviewers' comments (if any).</p>
                        </li>
                        <li><p className="mb-1">Carefully check the contents of your paper to ensure that <b>similarity
                            shall be less
                            than
                            30% (Overall) and &lt; 4% from a single source</b>. Otherwise, your paper may not be
                            included in
                            IEEE Xplore Digital Library.</p>
                        </li>
                        <li><p className="mb-1">While preparing the paper, please follow the IEEE Conference <a
                            href="/template/NCIM-conference-template-a4_update.docx" download>Microsoft
                            Word Template</a> or <a href="/template/IEEE_Conference_NCIM-2023-update2.zip"
                                                    download>LaTeX Template</a>.</p>
                        </li>
                        <li>Note that camera-ready paper should be limited to a minimum of four (4) pages and upto six
                            (6) pages, in the IEEE 2-column format including figures and references.
                        </li>

                    </ol>

                    <u><h6>Step-2: Copyright Information (at header and footer)</h6></u>

                    <ol>
                        <li><p><strong>Header:</strong> : Please add the conference title at the top-right side of the
                            first page of each paper as follows:</p>
                            <p style={{textAlign: "right"}} className="mb-0">2023 International Conference on
                                Next-Generation Computing, IoT and Machine Learning (NCIM)</p>
                            <p style={{textAlign: "right"}}>16-17 June 2023, Gazipur-1707, Bangladesh</p>
                        </li>

                        <li>
                            <p className="mb-1"><strong>Footer:</strong> Please add one IEEE Copyright Code according to
                                the following
                                conditions (generally it will be <strong>979-8-3503-1600-1/23/$31.00 ©2023
                                    IEEE)</strong> at the footer of the 1st page only (left column) of the paper.</p>
                            <ol type="a">
                                <li>
                                    <p className="mb-1">For papers in which all authors are employed by the US
                                        government, the copyright
                                        notice is: <strong> U.S. Government work not protected by U.S.
                                            copyright</strong></p>
                                </li>
                                <li>
                                    <p className="mb-1"> For papers in which all authors are employed by a Crown
                                        government (UK, Canada,
                                        and Australia), the copyright notice is: <strong>979-8-3503-1600-1/23/$31.00
                                            ©2023 Crown</strong></p>
                                </li>
                                <li>
                                    <p className="mb-1">For papers in which all authors are employed by the European
                                        Union, the copyright
                                        notice is: <strong>979-8-3503-1600-1/23/$31.00©2023 European Union </strong></p>
                                </li>

                                <li>
                                    <p className="mb-1">For all other papers the copyright notice
                                        is:<strong> 979-8-3503-1600-1/23/$31.00
                                            ©2023 IEEE</strong></p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <u><h6>Step 3: Prepare IEEE Compatible PDF using IEEE PDF exPress</h6></u>
                    <ol>
                        <li><p className="mb-1">Go to IEEE PDF eXpress Website at <a
                            href="https://ieee-pdf-express.org">https://ieee-pdf-express.org/</a></p></li>
                        <li><p className="mb-2">First Time Users:</p>
                            <ol type="a">
                                <li><p className="mb-1">Enter Conference ID (<strong>59001X</strong>), your email
                                    address, and choose a
                                    password. Continue to enter information as prompted.</p></li>
                                <li><p className="mb-1">Check that the contact information is still valid, and click
                                    "Submit".</p></li>
                                <li><p className="mb-1">An Online confirmation will be displayed and an email
                                    confirmation will be sent
                                    verifying your account setup</p></li>
                            </ol>
                        </li>
                        <li><p className="mb-2">Previous users, but for a new conference:</p>
                            <ol type="a">
                                <li><p className="mb-1">Enter Conference ID (<strong>59001X</strong>), your email
                                    address, and enter the
                                    password you used for your account.</p></li>
                                <li><p className="mb-1">When you click "Login", you'll receive an error saying you need
                                    to set up an
                                    account. Simply click "Continue". By entering your previously used email address and
                                    password combination, you will enable your old account for access to this new
                                    conference.</p></li>
                                <li><p className="mb-1">You will receive online and email confirmation of successful
                                    account setup.</p>
                                </li>
                            </ol>
                        </li>
                        <li><p className="mb-1">For each conference paper, click "Create New Title".</p></li>
                        <li><p className="mb-1">Enter identifying text for the paper (title is recommended but not
                            required)</p></li>
                        <li><p className="mb-1">After the source file conversion, the IEEE compatible pdf will be sent
                            to your email if
                            it passes the checking process. You need to upload both the IEEE compatible pdf and proof of
                            compatibility (jpg/pdf indicating pass verdict) while uploading the camera-ready files.</p>
                        </li>
                    </ol>

                    <u><h6>Step 4: Upload the Files at CMT3 System</h6></u>
                    <ol>
                        <li><p className="mb-1">Go to <a href="https://cmt3.research.microsoft.com/NCIM2023/"
                                                         target="_blank"
                                                         rel="noopener noreferrer">
                            CMT3</a> system's conference site.</p></li>
                        <li><p className="mb-1">Click on Submit IEEE Copyright Form and follow the next steps to fill
                            the eCF. (Please
                            submit copyright form for each paper - separately. Corresponding author /
                            Responsible author/ Co-author should do it. You must inform ALL co-authors (if any) about
                            this copyright form. After filling the eCF, you should download the pdf of eCF, it's copy
                            will
                            be sent to your email too).</p></li>
                        <li><p className="mb-1">Click on camera-ready submission and submit the necessary files. You
                            need to upload
                            the final IEEE compatible pdf of your paper (Renamed
                            as <strong>PaperID_Paper.pdf</strong>), proof of IEEE
                            compatibility check (Renamed as <strong>PaperID_pdfxpress.pdf</strong>), proof of eCF
                            (Renamed
                            as <strong>PaperID_eCF.pdf</strong>)
                            and source file (Word or LaTeX) of your paper along with necessary information such as
                            final paper title and final abstract.</p></li>
                        <li>
                            <p className="mb-1">If your paper's source file is a word document, simply upload the
                                document. If your source file
                                is a LaTeX project, please create a zip file of the project and upload the zip file.</p>
                        </li>
                    </ol>
                    <p style={{"text-align": "justify"}}>Important Notice: By completing these 4 steps, you have
                        completed the camera-ready submission.
                        After the submission, you must complete the online registration process as well. To register for
                        the conference, <Link to="/registration">Click Here</Link>.</p>

                    <h2 className="pt-4 title">Oral Presentation Preparation Guideline:</h2>
                    <p style={{"text-align": "justify"}}>
                        Prepare the presentation slides (i.e., only for oral presentations) as per the attached template and guidelines. Please Note:
                    </p>
                    <ul>
                        <li>The oral presentation should be in English with a time duration of 10 minutes, with an additional 3 minutes for Q&A.</li>
                        <li> Prepare two copies of presentation in Power-point and PDF and name the files as PaperID.pptx and PaperID.pdf </li>
                        <li> Email the presentation files to <b>ncim@duet.ac.bd</b>  as an attachment with a SUBJECT: PAPER ID – XXX by   <b>14 June (Wednesday), 2023</b>; where XXX is your Paper ID.</li>

                    </ul>





                    <h2 className="pt-4 title">Poster Presentation Guidelines:</h2>
                    <p style={{"text-align": "justify"}}>
                        There will be a 1-hour poster session, where all the presenters will have chance to present
                        physically infront of all the attendees of NCIM 2023 with their poster presentations. All the
                        participants hence are required to be prepare and make a color print presentation (i.e.,
                        possibly in art paper with a dimension of <i>height</i> (3 feet) × <i>width</i> (2 feet)) <a
                        href="/template/NCIM2023_PosterTemplate.pptx" download>Download Poster Template.pptx</a>. A
                        panel of expert
                        members will also roam around the poster presentation venue for sharing their comments and
                        suggestions in addition to evaluating the merit of the works. All the details program schedule
                        of the session will be communicated to the presenters in time.
                    </p>

                    <p style={{"text-align": "justify"}} className="mb-1">
                        Once registration of your poster paper is completed, please upload your poster (i.e., in *.pdf formats) at the link: <Link to="/sub_poster">Click Here</Link> by <b>13 June, 2023</b>.
                    </p>

                    <p style={{"text-align": "justify"}} className="mb-1">
                        The title of your poster should appear at the top in CAPITAL letters about 25mm (1″) high. Use
                        color to highlight and make your poster more attractive including pictures, diagrams, cartoons,
                        figures, etc., wherever possible rather than only texts. The author(s) name(s) and
                        affiliation(s) are to be put below the title.
                    </p>
                    <p style={{"text-align": "justify"}} className="mb-1">
                        Please note that your presented paper will be included in the NCIM 2023 conference proceedings
                        but won't be published in the IEEE Xplore digital library.
                    </p>
                    <p className="mb-1"> All the registered poster paper presenters are entitled to:</p>
                    <ul>
                        <li><strong> Have their papers published in the NCIM 2023 Proceedings</strong></li>
                        <li><strong> Get constructive suggestions and feedback from the experts in the related
                            field</strong></li>
                        <li><strong> Receive an attractive set of Conference Kits</strong></li>
                        <li><strong> Access to all the keynotes, technical sessions, tutorial and workshops</strong>
                        </li>
                    </ul>


                    <h2 className="pt-4 title">Paper Submission Guidelines</h2>

                    <div className="mt-4" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <p style={{"text-align": "justify"}}>Authors are invited to submit their original, unpublished
                            papers electronically (PDF format using the IEEE conference template) through conference
                            website. Paper submissions should be limited to a minimum of four (4) pages and upto six (6)
                            pages, in the IEEE 2-column
                            format including figures and references. All submissions will be reviewed double-blind by
                            the
                            Program Committee on the basis of technical quality, originality, and clarity of
                            presentation.
                            <b className="animated-text"> Accepted papers will be submitted for inclusion into IEEE
                                Xplore subject to meeting IEEE Xplore’s scope and quality requirements. IEEE Technical
                                Co-sponsorship for NCIM 2023 has been approved on 23 May, 2023 (Conference Id:
                                59001)</b>.
                        </p>

                        <ul>
                            <li>Make sure that no part of this paper (including Figure/Table/Algorithm) is copied from
                                other
                                sources. Make sure that you did not do any plagiarism or self-plagiarism? <b>The
                                    similarity
                                    shall be less than 30% (Overall) and &lt; 4% from a single source.</b>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4  " data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <b><p>Before submission</p></b>
                        <ul>
                            <li>Before submitting the manuscript, authors are requested to read and follow the
                                “IEEE Submission Policies” in <a
                                    href="https://conferences.ieeeauthorcenter.ieee.org/author-ethics/guidelines-and-policies/submission-policies/"
                                    target="_blank" rel="noopener noreferrer"> Click
                                    Here</a> and “IEEE Peer Review Policy” in <a
                                    href="https://conferences.ieeeauthorcenter.ieee.org/understand-peer-review/"
                                    target="_blank" rel="noopener noreferrer"> Click
                                    Here</a> .
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4  " data-aos="fade-up">
                        <b><p>Double-Blind Paper:</p></b>
                        <ul>
                            <li>The authors should not include their names, affiliations, postal addresses, and email
                                addresses in the initial manuscript. If it is not maintained the manuscript will be
                                immediately rejected.
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4  " data-aos="fade-up">
                        <b><p>Manuscript Template:</p></b>
                        <ul>
                            <li>Submissions must follow standard IEEE conference templates (Please download and use A4
                                Size Template). The manuscript template is provided website or can be downloaded from
                                IEEE website:

                                <a href="https://www.ieee.org/conferences/publishing/templates.html" target="_blank"
                                   rel="noopener noreferrer"> Click
                                    Here</a>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-4  " data-aos="fade-up">
                        <b><p>Microsoft CMT Submission:</p></b>
                        <ul>
                            <li>Each paper needs to be submitted electronically as a single PDF file through Microsoft
                                CMT:
                                {/*<a href="https://cmt3.research.microsoft.com/NCIM2023/" target="_blank"  rel="noopener noreferrer"> Click*/}
                                {/* Here</a>*/}
                            </li>
                        </ul>
                    </div>


                </div>
                <div className="col-md-3">
                    <h2 className="pt-4 title">DOWNLOAD TEMPLATE </h2>
                    <ListGroup variant="flush" className="submission" data-aos="fade-up">
                        <ListGroup.Item>
                            <a href="/template/IEEE_Conference_NCIM-2023-update2.zip" download>Latex Template</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <a href="/template/NCIM-conference-template-a4_update.docx" download>MSword Paper
                                Template</a>

                        </ListGroup.Item>
                        {/*<ListGroup.Item>*/}
                        {/*    <a href="/template/Copyright-Form.docx" download>Copyright Form</a>*/}

                        {/*</ListGroup.Item>*/}
                        {/*<ListGroup.Item>*/}
                        {/*    <a href="/template/Permission-Request-Form-Springer.doc" download>Permission Request*/}
                        {/*        Form</a>*/}

                        {/*</ListGroup.Item>*/}
                    </ListGroup>

                    <br/>
                    {/*<Proconf />*/}
                </div>
            </div>
        </Container>
    )

}

export default Submission;