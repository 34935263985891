import React from "react";
import {Table, Container} from "react-bootstrap";
import './registration.css';
import {Link} from "react-router-dom";

const Speakers = () => {
    return (
        <Container>
            <h2 className="pt-4 title">REGISTRATION</h2>
            <div className="row mt-4">
                <div className="col-md-12">
                    <h5 className="mb-3" data-aos="fade-up">Registration Fees: </h5>
                    <p>The following registration fees will be applied to all the participants(Authors/Attendees) of
                        NCIM 2023</p>

                    <Table striped bordered hover data-aos="fade-up">
                        <thead>
                        <tr>
                            <th rowSpan="3" colSpan="2" className="align-middle">Category</th>
                            {/*<th colSpan="4">Participation</th>*/}
                        </tr>
                        <tr>
                            <th colSpan="2" className="text-decoration-line-through text-danger">Early Bird Registration On or Before <br/>
                                31 May, 2023
                            </th>
                            <th colSpan="2">Registration Deadline<br/>
                                5 June, 2023
                            </th>
                        </tr>
                        <tr>
                            <th>General</th>
                            <th> IEEE Member **</th>
                            <th>General</th>
                            <th> IEEE Member **</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td rowSpan="4" className="align-middle">General Author</td>
                        </tr>
                        <tr>
                            <td>Local</td>
                            <td>BDT 8500</td>
                            <td>BDT 7500</td>
                            <td>BDT 9500</td>
                            <td>BDT 8500</td>
                        </tr>
                        <tr>
                            <td>SAARC Country</td>
                            <td>USD 155</td>
                            <td>USD 140</td>
                            <td>USD 165</td>
                            <td>USD 150</td>
                        </tr>
                        <tr>
                            <td>Other Countries</td>
                            <td>USD 190</td>
                            <td>USD 170</td>
                            <td>USD 215</td>
                            <td>USD 180</td>
                        </tr>

                        <tr>
                            <td rowSpan="4" className="align-middle">General Attendee</td>
                        </tr>
                        <tr>
                            <td>Local</td>
                            <td colSpan="2">BDT 6500</td>
                            <td colSpan="2">BDT 7500</td>
                        </tr>
                        <tr>
                            <td>SAARC Country</td>
                            <td colSpan="2">USD 120</td>
                            <td colSpan="2">USD 130</td>
                        </tr>
                        <tr>
                            <td>Other Countries</td>
                            <td colSpan="2">USD 150</td>
                            <td colSpan="2">USD 160</td>
                        </tr>

                        <tr>
                            <td rowSpan="4" className="align-middle">Student Author*</td>
                        </tr>
                        <tr>
                            <td>Local</td>
                            <td>BDT 6000</td>
                            <td>BDT 5500</td>
                            <td>BDT 6500</td>
                            <td>BDT 6000</td>
                        </tr>
                        <tr>
                            <td>SAARC Country</td>
                            <td>USD 140</td>
                            <td>USD 110</td>
                            <td>USD 150</td>
                            <td>USD 120</td>
                        </tr>
                        <tr>
                            <td>Other Countries</td>
                            <td>USD 170</td>
                            <td>USD 155</td>
                            <td>USD 180</td>
                            <td>USD 165</td>
                        </tr>

                        <tr>
                            <td rowSpan="4" className="align-middle">Student Attendee*</td>
                        </tr>
                        <tr>
                            <td>Local</td>
                            <td colSpan="2">BDT 5000</td>
                            <td colSpan="2">BDT 5500</td>
                        </tr>
                        <tr>
                            <td>SAARC Country</td>
                            <td colSpan="2">USD 120</td>
                            <td colSpan="2">USD 130</td>
                        </tr>
                        <tr>
                            <td>Other Countries</td>
                            <td colSpan="2">USD 150</td>
                            <td colSpan="2">USD 160</td>
                        </tr>


                        </tbody>
                    </Table>

                    <ul>
                        <li className="text-justify card_text">If an author has multiple accepted papers, the author has
                            to pay full registration fee for the first paper, 40% less of the regular registration for
                            the second and onward papers (<b>please note that conference kit and food are excluded for
                                such second and onward paper registration</b>).
                        </li>
                        <li className="text-justify card_text">Please note that the POSTER papers will not be sent to
                            IEEE for inclusion into IEEE Xplore Digital Library. However, Poster Paper Title, Author
                            Information and Abstract will be published in hard copy proceedings only. Thus,
                            <ul>
                                <li>
                                    The General Author registration fee for Poster paper will be deducted by an amount
                                    of Tk. 2000/= (USD 25 for
                                    foreign authors)
                                    for only ‘General Author’ category.
                                </li>
                                <li>
                                    The Student Author registration fee for Poster paper will be deducted by an amount of
                                    Tk. 1000/= (USD
                                    10 for foreign authors)
                                    for only ‘Student Author’ category.
                                </li>
                                <li>The register authors of Poster paper will get registration kits and access to all
                                    sessions including
                                    keynote speeches, technical
                                    sessions, invited talks, lunch, dinner, coffee breaks, etc.
                                </li>

                            </ul>
                        </li>

                        <li className="text-justify card_text">An Author has to upload the following documents in order
                            to avail the special discounted registration fees through the <Link to="/author_reg"> online registration. </Link>
                            <ul>
                                <li>*Student Category Discount – Proof of current studentship from the enrolled
                                    institution
                                </li>
                                <li>** IEEE Membership Category Discount – Proof of IEEE membership for the year 2023
                                </li>
                            </ul>
                        </li>
                        {/*<li><Link to="/author_reg">Oline Portal</Link></li>*/}

                    </ul>


                    <h5 className="mb-3 mt-5">Registration Information and Payment:</h5>
                    <p>Please fill up the required information in online registration form (click here) and confirm
                        registration by paying respective registration amount through Credit Card (VISA, Master) / Debit
                        Card (Nexus) / Mobile Wallet (Bkash, Nagad, Rocket, mCash, OK Wallet). For any kind of assistance please
                        contact Mr. Md. Sorwar Alam, Assistant Programmer, Deartment of CSE, DUET, Gazipur (Mobile No:
                        +8801621757014 and Email: sorwar@duet.ac.bd).</p>

                    Registration Link: <Link to="/author_reg">Click Here</Link>

                </div>
            </div>
        </Container>
    );
};

export default Speakers;
