import React, {useEffect, useState, useRef} from "react";
import {Table, Container} from "react-bootstrap";
import dateFormat from "dateformat";
import './registration.css';
import {useHistory} from "react-router-dom";
import {withRouter} from "react-router";
import {tk_lists} from "../../components/data/data";
import ncim from '../../components/assets/img/NCIMlogo.png'
import './registration.css';
import ReactToPrint from "react-to-print";
import AuthorReg from "./AuthorReg";
import axios from "axios";
import config from "../../config";
import SpinnerCom from "../../components/Spinner/Spinner";
import {makePayment, paymentStatus, verifyPayment} from "shurjopay-js";


const DetailReg = (props) => {

    const history = useHistory();
    const currentDate = new Date().toLocaleDateString();
    const componentRef = useRef();
    const [ipAddress, setIpAddress] = useState('');

    if (props.location.state) {
        const {formData, papers_id} = props.location.state;
        const [total, setTotal] = useState(0);
        const [loading, setLoading] = useState(false);
        const {
            registration_type, salutation, name, region_road, city, postal_code, country, nid_or_pass_id,
            email, mobile, institution, ieee_member, ieee_membership_no, ieee_membership_copy, student,
            student_id, student_reco,  payment_category, tracking_no
        } = formData;
        const payment = tk_lists.find(item => item.name === payment_category)

        const handleCancel = () => {
            history.goBack();
        }




        const handleClick = async () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional: Adds smooth scrolling effect
            });
            setLoading(true);

            const formDataSent = new FormData();
            Object.entries(formData).forEach(([key, item]) => {
                formDataSent.append(key, item)
            });

            formDataSent.append('creation_date', currentDate);
            formDataSent.append('total', total);
            formDataSent.append('currency', payment.short_code);
            for (let i = 0; i < papers_id.length; i++) {
                if( papers_id[i].value !== ''){
                    formDataSent.append('papers_id[]', papers_id[i].value);
                }
            }
            try {
                const response = await axios.post(`${config.apiUrlLaravel}/registration`, formDataSent);

                // console.log(response.data);

                if (response.data.info === 'success') {
                    const regInfo = response.data.data;
                    const order_id = regInfo.tracking_no;
                    const newData = {
                        'amount': regInfo.total_amount,
                        'customer_name': regInfo.name,
                        'customer_phone': regInfo.mobile,
                        'customer_email': regInfo.email,
                        'customer_address': regInfo.region_road,
                        'customer_city': regInfo.city,
                        'customer_post_code': regInfo.postal_code,
                        'currency': regInfo.currency,
                        'client_ip': ipAddress
                    };

                    const makePayment_details = await makePayment(order_id, newData);
                     // console.log(makePayment_details);

                    const {checkout_url} = makePayment_details;

                    // console.log(checkout_url);

                    if (checkout_url) {
                        window.location.href = checkout_url;
                    } else {
                        setLoading(false);
                        alert("Something Wrong on Your Payment");
                    }

                }
                // console.log(response.data);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };

        const calculateAmount = () => {
            let totalAmount = 0;

            if(registration_type==="AUTHOR"){
                papers_id.forEach((item, index) => {
                    if (item.value !== '') {
                        let discountedAmount = payment.amount;
                        if (index > 0) {
                            discountedAmount = payment.amount * 0.6; // apply 40% discount for the second and subsequent papers
                            totalAmount += discountedAmount;
                        } else {
                            totalAmount += payment.amount
                        }
                    }
                    setTotal(totalAmount.toFixed(2));
                });
            }else{
                setTotal(payment.amount.toFixed(2));
            }
        }

        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                const data = response.data;
                setIpAddress(data.ip);
            } catch (error) {
                console.log(error);
            }
        };

        // console.log(ipAddress);

        useEffect(() => {
            calculateAmount();
            fetchIpAddress();
        }, [])



        return (
            <Container>
                <h2 className="pt-4 title">Payment Detail</h2>

                {loading? <SpinnerCom />:
                    <div className="row">
                    <div className="col-md-10 mx-auto">
                        <div className="card" >
                            <div className="card-body" ref={componentRef}>
                                <div className="container mb-5 mt-3">
                                    <div className="row d-flex align-items-baseline">
                                        <div className="col-xl-9">
                                            <p style={{color: '#7e8d9f', fontSize: '20px'}}>Payment
                                                Detail <strong></strong></p>
                                        </div>
                                        <div className="col-xl-3 float-end">

                                            <ReactToPrint
                                                trigger={() => (
                                                    <button className="btn btn-light text-capitalize border-0"
                                                            data-mdb-ripple-color="dark" type='button'><i
                                                        className="fa fa-print text-primary"></i> Print
                                                    </button>
                                                )}
                                                content={() => componentRef.current}
                                                documentTitle="NCIM payment"
                                            />
                                        </div>
                                        <hr/>
                                    </div>

                                    <div className="container invoice">

                                        <div className="col-md-12">
                                            <div className="text-center">
                                                <i className="fa fa-mdb fa-4x ms-0" style={{color: '#5d9fc5'}}></i>
                                                <img src={ncim} alt=""/>
                                                <p className="pt-0">International Conference on Next-Generation
                                                    Computing, IoT and Machine Learning (NCIM 2023)</p>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-xl-7 ">
                                                <ul className="list-unstyled ml-2">
                                                    <li className="text-muted">To: <span
                                                        style={{color: '#5d9fc5'}}>{salutation}{' ' + name}</span>
                                                    </li>
                                                    <li className="text-muted">Reg. Type: {registration_type}</li>
                                                    <li className="text-muted">Address: {`${region_road}, ${city}, ${postal_code}, ${country}`}</li>
                                                    <li className="text-muted"><i
                                                        className="fa fa-envelope"></i> {email}</li>
                                                    <li className="text-muted"><i
                                                        className="fa fa-phone"></i> {mobile}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-5">
                                                <ul className="list-unstyled">
                                                    <li className="text-muted"><i className="fa fa-circle ml-1" style={{color: '#84B0CA'}}></i>
                                                        <span
                                                            className="fw-bold"> Tracking No:</span> {tracking_no}
                                                    </li>
                                                    <li className="text-muted"><i className="fa fa-circle ml-1" style={{color: '#84B0CA'}}></i>
                                                        <span
                                                            className="fw-bold"> Category:</span> {payment_category}
                                                    </li>
                                                    <li className="text-muted"><i className="fa fa-circle" style={{color: '#84B0CA'}}></i>
                                                        <span
                                                            className="fw-bold"> Creation Date: </span>{dateFormat(currentDate, 'mediumDate')}
                                                    </li>
                                                    <li className="text-muted"><i className="fa fa-circle"
                                                                                  style={{color: '#84B0CA'}}></i>
                                                        <span
                                                            className="me-1 fw-bold"> Status:</span><span
                                                            className="badge bg-warning text-black fw-bold">Unpaid</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {registration_type==="AUTHOR"? (
                                            <div className="row my-2 mx-1 justify-content-center">
                                            <table className="table table-striped table-borderless">
                                                <thead style={{backgroundColor: '#84B0CA'}} className="text-white">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Paper Id</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {papers_id.map((cart, index) => (
                                                    cart.value !== '' ?
                                                        <tr scope="row" key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{index === 0 ? cart.value : `${cart.value}(40% Discount)`}</td>
                                                            <td>{index === 0 ? payment.amount.toFixed(2) : (payment.amount * 0.6).toFixed(2)} {payment.short_code}</td>
                                                        </tr> : ''
                                                ))}
                                                </tbody>

                                            </table>
                                        </div>
                                        ): (
                                            <div className="row my-2 mx-1 justify-content-center">
                                                <table className="table table-striped table-borderless">
                                                    <thead style={{backgroundColor: '#84B0CA'}} className="text-white">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Attendee</th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr scope="row">
                                                        <td>1</td>
                                                        <td>ATTENDEE</td>
                                                        <td>{payment.amount} {payment.short_code}</td>
                                                    </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-xl-7">
                                                <p className="ms-3">Add additional notes and payment information</p>

                                            </div>
                                            <div className="col-xl-5">
                                                <ul className="list-unstyled">
                                                </ul>
                                                <p className="text-black float-start"><span
                                                    className="text-black me-3"> Total Amount</span><span
                                                    style={{fontSize: '20px'}}>{total} {payment.short_code}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-xl-2">
                                                <button onClick={handleCancel} type="button"
                                                        className="btn btn-primary text-capitalize"
                                                        style={{backgroundColor: '#ef4747'}}>Cancel
                                                </button>
                                            </div>
                                            <div className="col-xl-7">
                                                <p>Thank you for your registrations</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <button type="button" onClick={handleClick} className="btn btn-primary text-capitalize"
                                                        style={{backgroundColor: '#60bdf3'}}>Confirm Payment
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }


            </Container>
        );
    } else {
        history.goBack();
    }

};

export default withRouter(DetailReg);
