import Aos from "aos";
import React, { useEffect,  useState } from "react";
import {
  BrowserRouter as Router, Route, Switch, useLocation,
  withRouter
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import TopHeader from "./components/TopHeader/TopHeader";
import Committees from "./pages/Committees/Commitees";
import Home from './pages/Home/Home';
import Speakers from "./pages/Speakers/Speakers";
import Spinner from './components/Spinner/Spinner'
import CallForPaper from "./pages/CallForPaper/CallForPaper";
import Submission from "./pages/Submission/Submission";
import Registration from "./pages/registration/Registration";
import Schedule from "./pages/schedule/Schedule";
import Contact from "./pages/Contact/Contact";
import ImportantDate from "./pages/ImportantDate/ImportantDate";
import About from "./pages/about/About";
import Accommodation from "./pages/about/Accommodation";
import Tourists from "./pages/about/Tourists";
import AuthorReg from "./pages/registration/AuthorReg";
import DetailReg from "./pages/registration/DetailsReg";
import TestPay from "./pages/registration/TestPay";
import Return from "./pages/registration/Return";
import Invoice from "./pages/registration/Invoice";
import Poster from "./pages/registration/Poster";
import PopupBanner from "./components/PopupBanner/PopupBanner";


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

function App() {
   const [ spinner, setSpinner ] = useState(true);

  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    setTimeout(() => setSpinner(false), 500)
  },[]);


  return (

      spinner? (<Spinner />): (<Router>
      <ScrollToTop>
        <TopHeader />
        <Header />
        <div className="main">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/speakers">
              <Speakers />
            </Route>
            <Route exact path="/committees">
              <Committees />
            </Route>
            <Route exact path="/call_for_paper">
              <CallForPaper />
            </Route>
              <Route exact path="/submission">
              <Submission />
            </Route>
              <Route exact path="/registration">
              <Registration />
            </Route>
              <Route exact path="/schedule">
              <Schedule />
            </Route>
              <Route exact path="/important_dates">
              <ImportantDate />
            </Route>

             <Route exact path="/contact">
              <Contact />
            </Route>
             <Route exact path="/schedule">
              <Schedule />
            </Route>
            <Route exact path="/accommodation">
              <Accommodation />
            </Route>
            <Route exact path="/tourists">
              <Tourists />
            </Route>
            <Route exact path="/author_reg">
              <AuthorReg />
            </Route>

            <Route  path="/reg_detail">
              <DetailReg />
            </Route>

             <Route  path="/test_pay">
              <TestPay />
            </Route>
             <Route  path="/ipn">
              <Return />
            </Route>

             <Route  path="/invoice">
              <Invoice />
            </Route>
             <Route  path="/sub_poster">
              <Poster />
            </Route>

          </Switch>
        </div>
      </ScrollToTop>
     <Footer />
    </Router>)

  );
}

export default App;
