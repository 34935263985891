import config from "./src/config"; // const shurjopay_config = {
//     SP_ENDPOINT: "https://sandbox.shurjopayment.com",
//     SP_USERNAME: "sp_sandbox",
//     SP_PASSWORD: "pyyk97hu&6u6",
//     SP_PREFIX: "sp",
//     SP_RETURN_URL: config.dev,
// };

var shurjopay_config = {
  SP_ENDPOINT: "https://engine.shurjopayment.com",
  SP_USERNAME: "ncimduet",
  SP_PASSWORD: "ncimray77bsjt$yt",
  SP_PREFIX: "NCD",
  SP_RETURN_URL: config.production
}; //
// const shurjopay_config = {
//   SP_ENDPOINT: process.env.REACT_APP_SP_ENDPOINT,
//   SP_USERNAME:  process.env.REACT_APP_SP_USERNAME,
//   SP_PASSWORD:  process.env.REACT_APP_SP_PASSWORD,
//   SP_PREFIX:  process.env.REACT_APP_SP_PREFIX,
//   SP_RETURN_URL:  process.env.REACT_APP_SP_RETURN_URL,
// };
//

export { shurjopay_config };