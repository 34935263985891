import React from 'react';
import {Badge, Container, Card, ListGroup} from "react-bootstrap";
import config from "../../config";


const Schedule = () => {
    return (
        <Container>
            <h2 className="pt-4 title">Schedule</h2>
            <div className="row">
                <div className="col-md-9 m-auto">
                    <Card>
                   <Card.Body>
                       <ul style={{listStyle: 'none'}}>
                           <li > <h5 className="animated-text mb-3">Program Schedule:   <a href="/template/ProgramSchedule.pdf"  target="_blank">Click Here</a></h5></li>
                           <li > <h5 className="animated-text mb-3">Transport Schedule:   <a href="/template/TransportSchedule.pdf"  target="_blank">Click Here</a></h5></li>
                       </ul>



                   </Card.Body>
               </Card>
                </div>


            </div>
        </Container>
    )
}

export default Schedule;