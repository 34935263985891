import React from 'react';
import {Container, ListGroup, Badge} from "react-bootstrap";
import config from "../../config";
import {dateData} from "../../components/data/data";

const ImportantDate = () => {
  return (
      <Container>
            <h2 className="pt-4 title">Important Dates</h2>
            <div className="row">
                {/*<p className="d-flex justify-content-center align-items-center text-success fw-bold" >To Be Announced (TBA)</p>*/}
                <center>
                    <div className="col-md-8">
                        <ListGroup as="ul" variant="flush">
                            {dateData.map((item) => (
                                <div key={Math.random()}>

                                    <ListGroup.Item as="li" key={Math.random()}
                                                    className="d-flex justify-content-between align-items-start"
                                                    data-aos="fade-up">

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.heading}</div>
                                            </div>
                                        <div className="d-flex flex-column align-items-end">
                                            <Badge bg={item.color}
                                                   className={["p-2", item.status !== 'Active' ? 'text-decoration-line-through' : ''].join(' ')}
                                                   pill>
                                                {item.dateEvent}
                                            </Badge>

                                            {item.extension  &&
                                                <Badge bg={item.color} className={["p-2", "mt-2", item.heading==='Camera-Ready Submission'? '': 'text-decoration-line-through'].join(' ')} pill>
                                                    {item.extension} <br/>
                                                     (Extended)

                                                </Badge>
                                            }
                                             {item.extension && item.hardexten!=='' &&(
                                                <Badge bg="success" className="p-2 mt-2" pill>
                                                    {item.hardexten} <br/>
                                                     (Hard Deadline)
                                                </Badge>)
                                            }
                                        </div>

                                    </ListGroup.Item>



                                </div>
                            ))}

                        </ListGroup>
                    </div>
                </center>
            </div>
        </Container>
  )
}

export default ImportantDate;