import React, {useEffect, useState} from "react";
import {Container, ListGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import pub_image from "../assets/img/Springer-Logo.jpg";
import './footer.css';

const Footer = () => {

    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    // This function will scroll the window to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };

    return (
        <Container fluid className="footer mt-4">
            <div className="row mt-4 mb-4 container">
                <div className="col-md-7">
                    <center><h4 className="title_footer">LOCATION</h4></center>

                    <center>
                        <div>
                            <iframe
                                title="DUET-Location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.374537065247!2d90.41599951430241!3d24.017854184424923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755da4eced0c537%3A0x8d54b38a823b5212!2sDhaka%20University%20of%20Engineering%20and%20Technology%20(DUET)%2C%20Gazipur!5e0!3m2!1sen!2sbd!4v1655976586163!5m2!1sen!2sbd"
                                width="450" height="200" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">

                            </iframe>
                        </div>
                    </center>


                </div>
                <div className="col-md-5">
                    <center><h4 className="title_footer"> QUICK NAVIGATION</h4></center>
                    <center>
                        <ListGroup variant="flush" className="links text-center">
                            <ListGroup.Item>
                                <Link to="/call_for_paper">Call for Paper</Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to="/registration">Registration</Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to="/submission">Submission</Link>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Link to="/speakers">Speakers</Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </center>
                </div>
                {/*<div className="col-md-4">*/}
                {/*     <center><h4 className="title_footer">ADDRESS <br/>*/}
                {/*      </h4></center>*/}
                {/*    <p>Dhaka University of Engineering & Technology, Gazipur, Gazipur-1707, Bangladesh</p>*/}
                {/*    /!*<center><h4 className="title_footer">PUBLICATION PARTNER <br/>*!/*/}
                {/*    /!*    (Approval Pending)</h4></center>*!/*/}
                {/*    /!*<img src={pub_image} className="rounded mx-auto d-block" width="160" height="52" alt="..."></img>*!/*/}
                {/*</div>*/}

            </div>
            <hr/>
            <div className="row">
                <div className="col-md-12 text-center mb-3">
                    Copyright © 2023 Department of Computer Science and Engineering, DUET
                    <br/>
                    Design and Developed by Engr. Md. Sorwar Alam, Assistant Programmer, Dept of CSE
                </div>
            </div>
            {showButton && (
                <span className="back_to_top"><i className="fa fa-chevron-up" onClick={scrollToTop}></i></span>
            )}
        </Container>
    );
};

export default Footer;
