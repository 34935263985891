export  default {
    'confDate': ' 16-17 June, 2023',
    'extension': '10 April, 2023',
    'PaperSubmission': '01 April, 2023',
    'PaperSubmissionDay': '31',
    'Notification': '20 May, 2023',
    'NotificationDay': '23',
    'CameraReady': '27 May, 2023',
    'CameraReadyDay': '30',
    'RegistrationDeadline': '31 May, 2023',
    'RegistrationDeadlineDay': '31',
    //apiUrlLaravel: 'http://127.0.0.1:8000/api',
    production: 'https://confncim.com/ipn',
    dev: 'http://localhost:3000/ipn',
    apiUrlLaravel: 'https://admin.confncim.com/api',
    apiUrlImage: 'https://admin.confncim.com/api/upload'
}