import React, {useState, useEffect} from 'react';
import {Form, Button, Card, Row, Col} from "react-bootstrap";
import axios from "axios";
import {countriesData, countryList, papers, tk_lists} from "../../components/data/data";
import {useHistory} from 'react-router-dom';
import config from "../../config";



const AuthorReg = () => {
    const [formData, setFormData] = useState({
        registration_type: '',
        salutation: '',
        name: '',
        region_road: '',
        city: '',
        postal_code: '',
        country: '',
        nid_or_pass_id: '',
        email: '',
        mobile: '',
        institution: '',
        ieee_member: '',
        ieee_membership_no: '',
        ieee_membership_copy: null,
        student: '',
        student_id: null,
        student_reco: null,
        payment_category: '',
        tracking_no: '',
        t_shirt_size: '',
        paper_type: ''
    });
    const {
        registration_type, salutation, name, region_road, city, postal_code,
        country, nid_or_pass_id, email, mobile, institution,
        ieee_member, ieee_membership_no, ieee_membership_copy, student, student_id,
        student_reco, t_shirt_size, payment_category, tracking_no, paper_type
    } = formData;

    const history = useHistory()

    const [inputs, setInputs] = useState([{value: ''}]);
    const [countries, setCountries] = useState([]);
    const [oralData, setOralData] = useState([]);
    const [posterData, setPosterData] = useState([]);

    const [showFileInput, setShowFileInput] = useState(false);
    const [showStudent, setShowStudent] = useState(false);
    const [payment, setPayment] = useState({})

    const handleChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});

        if (e.target.files) {
            setFormData({...formData, [e.target.name]: e.target.files[0]});
        }
        if (e.target.name === 'payment_category') {
            const payment = tk_lists.find(item => item.name === e.target.value)
            setPayment(payment);
        }
    };

    const generateTrackingNumber=()=> {
        const date = new Date();
        const timestamp = date.getTime();
        const tracking_no=timestamp.toString();
        setFormData({...formData, ['tracking_no']:tracking_no});
    }

    const getOralData=async ()=>{
        const response = await axios.get(`${config.apiUrlLaravel}/papers`);
        setOralData(response.data.papers)
    }
    const getPosterData=async ()=>{
        const response = await axios.get(`${config.apiUrlLaravel}/poster`);
        setPosterData(response.data.papers)
    }

    useEffect(() => {
        generateTrackingNumber();
        getOralData();
        getPosterData();
    }, []);


    const handleMemberTypeChange = e => {
        const selectedType = e.target.value;
        // setMemberType(selectedType);
        setFormData({...formData, [e.target.name]: e.target.value});
        setShowFileInput(selectedType === 'Student' || selectedType === 'Professional');
    };

    const handleStudentTypeChange = e => {
        const selectedType = e.target.value;
        setFormData({...formData, [e.target.name]: e.target.value});
        setShowStudent(selectedType === 'Yes');
    };

    function handleInputChange(event, index) {
        const newValue = event.target.value;
        const newInputs = [...inputs]
        newInputs[index].value = newValue;
        setInputs(newInputs);
    }

    function handleAddInput() {
        setInputs(prevInputs => [...prevInputs, {value: ''}]);

    }

    function handleRemoveInput(index) {
        const updatedInputs = [...inputs];
        updatedInputs.splice(index, 1);
        setInputs(updatedInputs);

    }

    const inputFields = inputs.map((input, index) => {
        const isLastInput = index === inputs.length - 1;
        const isFirstInput = index === 0;
        const showAddButton = isLastInput && inputs.length < 5;
        const showRemoveButton = !isFirstInput;
        return (
            <div key={index}>
                <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
                    <Form.Label column sm={3}>Paper ID</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            as="select"
                            onChange={(event) => handleInputChange(event, index)}
                            value={input.value}
                            name="papers[]"
                        >
                            <option value="">Select an option</option>

                            {paper_type === 'Oral' ? (
                                oralData.map((item) => (
                                    <option value={item.acceptance_paper_id} key={item.acceptance_paper_id}>
                                        {item.acceptance_paper_id}
                                    </option>
                                ))
                            ) : (
                                posterData.map((item) => (
                                    <option value={item.acceptance_paper_id} key={item.acceptance_paper_id}>
                                        {item.acceptance_paper_id}
                                    </option>
                                ))
                            )}
                            {/*{paper_type!=='Poster'? oralData.map((item) => (*/}
                            {/*    <option value={item.acceptance_paper_id} key={Math.random()}>{item.acceptance_paper_id}</option>*/}
                            {/*)):*/}
                            {/*posterData.map((item) => (*/}
                            {/*    <option value={item.acceptance_paper_id} key={Math.random()}>{item.acceptance_paper_id}</option>*/}
                            {/*))*/}
                            {/*}*/}
                        </Form.Control>
                    </Col>
                    {showAddButton && (
                        <Col sm={2}>
                            <Button variant="secondary" onClick={handleAddInput}>
                                Add
                            </Button>
                        </Col>
                    )}
                    {showRemoveButton && (
                        <Col sm={2}>
                            <Button variant="danger" onClick={() => handleRemoveInput(index)}>
                                Remove
                            </Button>
                        </Col>
                    )}
                </Form.Group>
            </div>
        );
    });


    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        let errors = {};

        if (!formData.registration_type) {
            errors.registration_type = 'Registration type is required';
        }

        if (!formData.salutation) {
            errors.salutation = 'Salutation is required';
        }

        if (!formData.name) {
            errors.name = 'Name is required';
        }

        if (!formData.region_road) {
            errors.region_road = 'Region/Road is required';
        }

        if (!formData.city) {
            errors.city = 'City is required';
        }

         if (!formData.postal_code) {
            errors.postal_code = 'Postal Code is required';
        }

        if (!formData.country) {
            errors.country = 'Country is required';
        }

        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Invalid email address';
        }

        if (!formData.mobile) {
            errors.mobile = 'Mobile number is required';
        }
        if (formData.ieee_member === 'Student' || formData.ieee_member === 'Professional') {
            if (!formData.ieee_membership_no) {
                errors.ieee_membership_no = 'IEEE membership number is required';
            }

            if (!formData.ieee_membership_copy) {
                errors.ieee_membership_copy = 'IEEE membership copy is required';
            }

            if (ieee_membership_copy) {
                const allowedTypes = ["image/jpeg", "image/png"];
                if (!allowedTypes.includes(ieee_membership_copy.type)) {
                    errors.ieee_membership_copy = 'Please select a valid file type (JPEG/PNG).';

                }

            }
        }

        if (formData.student === 'Yes') {
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!formData.student_id) {
                errors.student_id = 'Student ID is required';
            }
            if (student_id) {
                const allowedTypes = ["image/jpeg", "image/png"];
                if (!allowedTypes.includes(student_id.type)) {
                    errors.student_id = 'Please select a valid file type (JPEG/PNG).';

                }

            }


            if (!formData.student_reco) {
                errors.student_reco = 'Student recommendation letter is required';
            }
            if (student_reco) {
                const allowedTypes = ["image/jpeg", "image/png"];
                if (!allowedTypes.includes(student_reco.type)) {
                    errors.student_reco = 'Please select a valid file type (JPEG/PNG).';

                }

            }

        }

        if (!formData.payment_category) {
            errors.payment_category = 'Payment type is required';
        }

        if (inputs && registration_type === "AUTHOR") {
            if (inputs[0].value === '') {
                errors.payment_category = 'Please select a Paper ID';
            }
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };



    const handleSubmit = async e => {
        e.preventDefault();

        if (validateForm()) {
            history.push({
                pathname: '/reg_detail',
                state: {formData: formData, papers_id: inputs}
            });
        }
    };
    const oralPapers=(
        <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
            <Form.Label column sm={3}>Payment Category</Form.Label>
            <Col sm={9}>
                <Form.Select name="payment_category" value={payment_category} required
                             onChange={handleChange}>
                    <option value="">--Select--</option>
                    {tk_lists.map((item) => (
                        item.type === registration_type ? <option value={item.name}
                                                                  key={Math.random()}>{item.name} ({item.amount + ' ' + item.short_code})</option> : ''

                    ))}
                </Form.Select>
            </Col>
        </Form.Group>
    )

     const posterPapers=(
        <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">
            <Form.Label column sm={3}>Payment Category</Form.Label>
            <Col sm={9}>
                <Form.Select name="payment_category" value={payment_category} required
                             onChange={handleChange}>
                    <option value="">--Select--</option>
                    {tk_lists.map((item) => (
                        item.type === 'Poster' ? <option value={item.name}
                                                                  key={Math.random()}>{item.name} ({item.amount + ' ' + item.short_code})</option> : ''
                    ))}
                </Form.Select>
            </Col>
        </Form.Group>
    )

    return (

        <div className="container">
            <h2 className="pt-4 title">REGISTRATION</h2>

            <h4 style={{textAlign: 'center', color: 'red', marginTop: '20px'}}>Registration time is over</h4>
            {/*<div className="row">*/}
            {/*    <div className="col-md-8 mx-auto">*/}
            {/*        <Card className="shadow" data-aos="fade-up">*/}
            {/*            <Card.Header>Registration Form (For any queries, please call +8801621757014)</Card.Header>*/}
            {/*            <Card.Body>*/}
            {/*                <Form onSubmit={handleSubmit} encType="multipart/form-data">*/}
            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Registration Type <span*/}
            {/*                            className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Select name="registration_type" value={registration_type}*/}
            {/*                                         onChange={handleChange} required>*/}
            {/*                                <option value="">--Select--</option>*/}
            {/*                                <option value="AUTHOR">AUTHOR</option>*/}
            {/*                                <option value="ATTENDEE">ATTENDEE</option>*/}
            {/*                            </Form.Select>*/}
            {/*                            {formErrors.registration_type &&*/}
            {/*                                <span className="error">{formErrors.registration_type}</span>}*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Salutation<span*/}
            {/*                            className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Select value={salutation} name="salutation" onChange={handleChange}*/}
            {/*                                         required>*/}
            {/*                                <option value="">--Select--</option>*/}
            {/*                                <option value="Mr.">Mr.</option>*/}
            {/*                                <option value="Ms.">Ms.</option>*/}
            {/*                                <option value="Mrs.">Mrs.</option>*/}
            {/*                                <option value="Dr.">Dr.</option>*/}

            {/*                            </Form.Select>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}
            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Name<span className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control type='text' name='name' value={name} placeholder="Enter Name"*/}
            {/*                                          onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Address<span className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={5} className="mb-2">*/}
            {/*                            <Form.Control type='text' name='region_road' value={region_road}*/}
            {/*                                          placeholder="Region/Road" onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}
            {/*                        <Col sm={4} className="mb-2">*/}
            {/*                            <Form.Control type='text' name='city' value={city}*/}
            {/*                                          placeholder="City" onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}
            {/*                        <Form.Label column sm={3}></Form.Label>*/}
            {/*                        <Col sm={5}>*/}
            {/*                            <Form.Control type='text' name='postal_code' value={postal_code}*/}
            {/*                                          placeholder="Postal Code" onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}

            {/*                        <Col sm={4}>*/}
            {/*                            <Form.Select name="country" value={country} onChange={handleChange} required>*/}
            {/*                                <option value="">--Select--</option>*/}
            {/*                                {countriesData.map((item) => (*/}
            {/*                                    <option value={item.value} key={Math.random()}>{item.value}</option>*/}
            {/*                                ))}*/}
            {/*                            </Form.Select>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Passport/National ID</Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control type='text' name='nid_or_pass_id' value={nid_or_pass_id}*/}
            {/*                                          placeholder="Passport/National ID" onChange={handleChange}*/}
            {/*                            />*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalEmail" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Email<span className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control type='email' name='email' placeholder="Enter your email"*/}
            {/*                                          value={email} onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalPhone" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Mobile<span className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control type='text' placeholder="Enter your mobile number" name='mobile'*/}
            {/*                                          value={mobile} onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}
            {/*                    <Form.Group as={Row} controlId="formHorizontalPhone" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Institution<span*/}
            {/*                            className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control type='text' name='institution' placeholder="Enter Institution"*/}
            {/*                                          value={institution} onChange={handleChange}*/}
            {/*                                          required/>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalPhone" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>T-shirt-Size<span*/}
            {/*                            className="text-danger">*</span></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                             <Form.Select value={t_shirt_size} name="t_shirt_size" onChange={handleChange}*/}
            {/*                                         required>*/}
            {/*                                <option value="">--Select--</option>*/}
            {/*                                <option value="S">S</option>*/}
            {/*                                <option value="M">M</option>*/}
            {/*                                <option value="L">L</option>*/}
            {/*                                <option value="XL">XL</option>*/}
            {/*                                <option value="XXL">XXL</option>*/}
            {/*                            </Form.Select>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row} controlId='formMemberType' className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>IEEE Member</Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control as='select' name="ieee_member" onChange={handleMemberTypeChange}>*/}
            {/*                                <option value=''>--Select--</option>*/}
            {/*                                <option value=''>Not Applicable</option>*/}
            {/*                                <option value='Student'>Student</option>*/}
            {/*                                <option value='Professional'>Professional</option>*/}
            {/*                            </Form.Control>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}
            {/*                    {showFileInput && (*/}
            {/*                        <Form.Group as={Row} controlId='formFile' className="mb-2">*/}
            {/*                            <Form.Label column sm={3}></Form.Label>*/}
            {/*                            <Col sm={4}>*/}
            {/*                                <Form.Control type='text' placeholder="Membership Number"*/}
            {/*                                              name='ieee_membership_no' value={ieee_membership_no}*/}
            {/*                                              onChange={handleChange}/>*/}
            {/*                                <span>Membership No </span> <span*/}
            {/*                            className="text-danger">*</span>*/}
            {/*                                {formErrors.ieee_membership_no &&*/}
            {/*                                    <span className="text-danger">{formErrors.ieee_membership_no}</span>}*/}
            {/*                            </Col>*/}
            {/*                            <Col sm={5}>*/}
            {/*                                <Form.Control type='file' name="ieee_membership_copy"*/}
            {/*                                              onChange={handleChange}/>*/}
            {/*                                <span>Membership Copy (.jpg, .png)</span> <span*/}
            {/*                            className="text-danger">*</span>*/}
            {/*                                {formErrors.ieee_membership_copy &&*/}
            {/*                                    <span className="text-danger">{formErrors.ieee_membership_copy}</span>}*/}
            {/*                            </Col>*/}
            {/*                        </Form.Group>*/}
            {/*                    )}*/}

            {/*                    <Form.Group as={Row} controlId='formMemberType' className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Student</Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Control as='select' name="student" onChange={handleStudentTypeChange}>*/}
            {/*                                <option value=''>--Select--</option>*/}
            {/*                                <option value='Yes'>Yes</option>*/}
            {/*                                <option value='No'>No</option>*/}
            {/*                            </Form.Control>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}
            {/*                    {showStudent && (*/}
            {/*                        <Form.Group as={Row} controlId='formFile' className="mb-2">*/}
            {/*                            <Form.Label column sm={3}></Form.Label>*/}

            {/*                            <Col sm={4}>*/}
            {/*                                <Form.Control type='file' name="student_id" onChange={handleChange}/>*/}
            {/*                                <span>Upload ID Card (.jpg ,.png)</span> <span*/}
            {/*                            className="text-danger">*</span> <br/>*/}
            {/*                                {formErrors.student_id &&*/}
            {/*                                    <span className="text-danger">{formErrors.student_id}</span>}*/}
            {/*                            </Col>*/}
            {/*                            <Col sm={5}>*/}
            {/*                                <Form.Control type='file' name="student_reco" onChange={handleChange}/>*/}
            {/*                                <span>Upload Recommendation from Head/Chairman/Dean (.jpg, .png)</span> <span*/}
            {/*                            className="text-danger">*</span> <br/>*/}
            {/*                                {formErrors.student_reco &&*/}
            {/*                                    <span className="text-danger">{formErrors.student_reco}</span>}*/}
            {/*                            </Col>*/}

            {/*                        </Form.Group>*/}
            {/*                    )}*/}

            {/*                      { registration_type !== 'ATTENDEE' ?*/}
            {/*                          <Form.Group as={Row} controlId='formMemberType' className="mb-2">*/}
            {/*                              <Form.Label column sm={3}>Paper Type</Form.Label>*/}
            {/*                              <Col sm={9}>*/}
            {/*                                  <Form.Control as='select' name="paper_type" onChange={handleChange}>*/}
            {/*                                      <option value=''>--Select--</option>*/}
            {/*                                      <option value='Oral'>Oral Paper</option>*/}
            {/*                                      <option value='Poster'>Poster Paper</option>*/}
            {/*                                  </Form.Control>*/}
            {/*                              </Col>*/}
            {/*                          </Form.Group>*/}
            {/*                          : ''}*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}>Payment Category</Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            <Form.Select name="payment_category" value={payment_category} required*/}
            {/*                                         onChange={handleChange}>*/}
            {/*                                <option value="">--Select--</option>*/}
            {/*                                {tk_lists.map((item) => {*/}
            {/*                                    if(paper_type === 'Oral' || registration_type==='ATTENDEE' ){*/}
            {/*                                        if (item.type === registration_type) {*/}
            {/*                                            return (*/}
            {/*                                                <option value={item.name} key={Math.random()}>*/}
            {/*                                                    {item.name} ({item.amount + ' ' + item.short_code})*/}
            {/*                                                </option>*/}
            {/*                                            );*/}
            {/*                                        }*/}
            {/*                                    }*/}
            {/*                                    else if (item.type ===paper_type) {*/}
            {/*                                        return (*/}
            {/*                                            <option value={item.name} key={Math.random()}>*/}
            {/*                                                {item.name} ({item.amount + ' ' + item.short_code})*/}
            {/*                                            </option>*/}
            {/*                                        );*/}
            {/*                                    }*/}
            {/*                                })}*/}
            {/*                            </Form.Select>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}



            {/*                    {payment_category && registration_type !== 'ATTENDEE' ? inputFields : ''}*/}

            {/*                    <Form.Group as={Row} controlId="formHorizontalName" className="mb-2">*/}
            {/*                        <Form.Label column sm={3}></Form.Label>*/}
            {/*                        <Col sm={9}>*/}
            {/*                            {formErrors.payment_category &&*/}
            {/*                                <span className="text-danger">{formErrors.payment_category}</span>}*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Row}>*/}
            {/*                        <Col sm={{span: 7, offset: 5}}>*/}
            {/*                            <Button variant='primary' type='submit'>*/}
            {/*                                Process Registration*/}
            {/*                            </Button>*/}
            {/*                        </Col>*/}
            {/*                    </Form.Group>*/}

            {/*                </Form>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </div>*/}
            {/*</div>*/}


        </div>
    );

}


export default AuthorReg;