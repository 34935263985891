import React from 'react';
import {Card, Container} from "react-bootstrap";

const Contact = () => {
    return (
        <Container>
            <h2 className="pt-4 title">CONTACT</h2>

            <div className="row">
                <div className="col-md-6">
                    <Card >

                        <Card.Body data-aos="fade-down">
                            <Card.Title>Md. Obaidur Rahman</Card.Title>

                            <Card.Text >
                                <span className='fw-bold'>General Chair, NCIM 2023</span> <br/>
                                Head, Department of Computer Science and Engineering <br/>
                                Dhaka University of Engineering & Technology (DUET), Gazipur
                            </Card.Text>
                            <Card.Text>
                                Address: <br/>
                                Office of Head, 2nd Floor, <br/>
                                Old Academic Building <br/>
                                Department of Computer Science and Engineering, <br/> Dhaka University of Engineering
                                & Technology (DUET), Gazipur, Bangladesh.
                                <br/>
                               <b>Email: </b> orahman@duet.ac.bd <br/>
                               <b>Mobile: </b> +8801930706863
                                <br/>
                                <b>Website:</b> <a href="https://profile.duet.ac.bd/u/orahman" target="_blank"
                                                   rel="noopener noreferrer">https://www.duet.ac.bd/orahman/</a>

                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6">

                    <Card>
                        <Card.Body data-aos="fade-up">
                            <Card.Title>Rafiqul Islam</Card.Title>
                            <Card.Text>
                                <span className='fw-bold'>Organizing Secretary, NCIM 2023</span> <br/>
                                Professor, Department of Computer Science and Engineering <br/>
                                Dhaka University of Engineering & Technology (DUET), Gazipur
                            </Card.Text>
                            <Card.Text>
                                Address: <br/>
                                Room No.: 7008, 6th Floor<br/>
                                New Academic Building <br/>
                                Department of Computer Science and Engineering, <br/> Dhaka University of Engineering
                                & Technology (DUET), Gazipur, Bangladesh.
                                <br/>
                               <b>Email: </b> rafiqul.islam@duet.ac.bd <br/>
                               <b>Mobile: </b> +8801911350163
                                <br/>
                                <b> Website:</b> <a href="https://profile.duet.ac.bd/u/rafiqul.islam" target="_blank"
                                                    rel="noopener noreferrer">https://profile.duet.ac.bd/u/rafiqul.islam</a>
                            </Card.Text>

                        </Card.Body>
                    </Card>

                </div>
            </div>
        </Container>
    )
}

export default Contact;