
export const track={
    'track1': [
        'Software reliability and Quality Assurance',
        'Optimal Testing and System Software ',
        'Blockchain Technologies',
        'Cloud and Edge Computing',
        'Parallel and Distributed Computing',
        'Ubiquitous/Mobile Computing'
    ],
    'track2':[
        'Wireless Communication',
        '5G and 6G Technologies',
        'Cyber Security',
        'Big Data Analytics',
        'IoT Security, Privacy and Governance',
        'Mobile Systems and Mobility'
    ],
    'track3':[
        'Augmented and Virtual Reality',
        'Natural Language Processing ',
        'Transfer, Deep and Federated Learning',
        'AI in Robotics and Automation',
        'Heuristics and AI Planning',
        'Zero-Shot, One-Shot and Few-Shot Learning'
    ],
    'track4':[
        'Signal and Image Processing',
        'Image Restoration and Reconstruction ',
        'Image De-noising and Super resolution',
        'Image Segmentation, Recognition and Classification',
        'Object Tracking',
        'Medical Image Processing'
    ]

}

export const dateData=[
    {heading: 'Paper Submission Deadline', dateEvent: '01 April, 2023', extension: '10 April, 2023',  hardexten: '15 April, 2023',status: 'Disable', color: 'danger' },
    {heading: 'Notification of Acceptance', dateEvent: '24 May, 2023', status: 'Active', color: 'success' },
    {heading: 'Camera-Ready Submission', dateEvent: '30 May, 2023', status: 'Disable', color: 'info',  extension: '05 June, 2023', hardexten: '' },
    {heading: 'Early Bird Registration', dateEvent: '31 May, 2023', status: 'Disable', color: 'danger' },
    {heading: 'Registration Deadline', dateEvent: '05 June, 2023', status: 'Active', color: 'success' },
    {heading: 'Conference Date', dateEvent: '16-17 June, 2023', status: 'Active', color: 'danger' },
]

export const papers=[
    {id: 134},
    {id: 137},
    {id: 139},
    {id: 164},
    {id: 174},
    {id: 184},
    {id: 194}
]



export const tk_lists_early=[
    {name: 'General Author/Local', amount: 8500, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'General Author/Local/ IEEE Member', amount: 7500, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'General Author/SAARC Country', amount: 155, short_code: 'USD', type: 'AUTHOR'},
    {name: 'General Author/SAARC Country/IEEE Member', amount: 140, short_code: 'USD', type: 'AUTHOR'},
    {name: 'General Author/Other Countries',amount: 190, short_code: 'USD', type: 'AUTHOR'},
    {name: 'General Author/Other Countries/IEEE Member',amount: 170, short_code: 'USD', type: 'AUTHOR'},

    {name: 'General Attendee/Local', amount: 6500, short_code: 'BDT', type: 'ATTENDEE'},
    {name: 'General Attendee/SAARC Country', amount: 120, short_code: 'USD', type: 'ATTENDEE'},
    {name: 'General Attendee/Other Countries',amount: 150, short_code: 'USD', type: 'ATTENDEE'},

    {name: 'Student  Author/Local', amount: 6000, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'Student  Author/Local/ IEEE Member', amount: 5500, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'Student  Author/SAARC Country', amount: 140, short_code: 'USD', type: 'AUTHOR'},
    {name: 'Student  Author/SAARC Country/IEEE Member', amount: 110, short_code: 'USD', type: 'AUTHOR'},
    {name: 'Student  Author/Other Countries',amount: 170, short_code: 'USD', type: 'AUTHOR'},
    {name: 'Student  Author/Other Countries/IEEE Member',amount: 155, short_code: 'USD', type: 'AUTHOR'},

    {name: 'Student Attendee/Local', amount: 5000, short_code: 'BDT', type: 'ATTENDEE'},
    {name: 'Student Attendee/SAARC Country', amount: 120, short_code: 'USD', type: 'ATTENDEE'},
    {name: 'Student Attendee/Other Countries',amount: 150, short_code: 'USD', type: 'ATTENDEE'},

    {name: 'General Author/Local for Poster', amount: 6500, short_code: 'BDT', type: 'Poster'},
    {name: 'General Author/Local/ IEEE Member for Poster ', amount: 5500, short_code: 'BDT', type: 'Poster'},
    {name: 'General Author/SAARC Country for Poster', amount: 130, short_code: 'USD', type: 'Poster'},
    {name: 'General Author/SAARC Country/IEEE Member for Poster', amount: 115, short_code: 'USD', type: 'Poster'},
    {name: 'General Author/Other Countries for Poster',amount: 165, short_code: 'USD', type: 'Poster'},
    {name: 'General Author/Other Countries/IEEE Member for Poster',amount: 145, short_code: 'USD', type: 'Poster'},

    {name: 'Student  Author/Local for Poster', amount: 5000, short_code: 'BDT', type: 'Poster'},
    {name: 'Student  Author/Local/ IEEE Member for Poster', amount: 4500, short_code: 'BDT', type: 'Poster'},
    {name: 'Student  Author/SAARC Country for Poster', amount: 130, short_code: 'USD', type: 'Poster'},
    {name: 'Student  Author/SAARC Country/IEEE Member for Poster', amount: 100, short_code: 'USD', type: 'Poster'},
    {name: 'Student  Author/Other Countries for Poster',amount: 160, short_code: 'USD', type: 'Poster'},
    {name: 'Student  Author/Other Countries/IEEE Member for Poster',amount: 145, short_code: 'USD', type: 'Poster'},

]


export const tk_lists=[
    {name: 'General Author/Local', amount: 9500, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'General Author/Local/ IEEE Member', amount: 8500, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'General Author/SAARC Country', amount: 165, short_code: 'USD', type: 'AUTHOR'},
    {name: 'General Author/SAARC Country/IEEE Member', amount: 150, short_code: 'USD', type: 'AUTHOR'},
    {name: 'General Author/Other Countries',amount: 215, short_code: 'USD', type: 'AUTHOR'},
    {name: 'General Author/Other Countries/IEEE Member',amount: 180, short_code: 'USD', type: 'AUTHOR'},

    {name: 'General Attendee/Local', amount: 7500, short_code: 'BDT', type: 'ATTENDEE'},
    {name: 'General Attendee/SAARC Country', amount: 130, short_code: 'USD', type: 'ATTENDEE'},
    {name: 'General Attendee/Other Countries',amount: 160, short_code: 'USD', type: 'ATTENDEE'},

    {name: 'Student  Author/Local', amount: 6500, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'Student  Author/Local/ IEEE Member', amount: 6000, short_code: 'BDT', type: 'AUTHOR'},
    {name: 'Student  Author/SAARC Country', amount: 150, short_code: 'USD', type: 'AUTHOR'},
    {name: 'Student  Author/SAARC Country/IEEE Member', amount: 120, short_code: 'USD', type: 'AUTHOR'},
    {name: 'Student  Author/Other Countries',amount: 180, short_code: 'USD', type: 'AUTHOR'},
    {name: 'Student  Author/Other Countries/IEEE Member',amount: 165, short_code: 'USD', type: 'AUTHOR'},

    {name: 'Student Attendee/Local', amount: 5500, short_code: 'BDT', type: 'ATTENDEE'},
    {name: 'Student Attendee/SAARC Country', amount: 130, short_code: 'USD', type: 'ATTENDEE'},
    {name: 'Student Attendee/Other Countries',amount: 160, short_code: 'USD', type: 'ATTENDEE'},

    {name: 'General Author/Local for Poster', amount: 7500, short_code: 'BDT', type: 'Poster'},
    {name: 'General Author/Local/ IEEE Member for Poster ', amount: 6500, short_code: 'BDT', type: 'Poster'},
    {name: 'General Author/SAARC Country for Poster', amount: 140, short_code: 'USD', type: 'Poster'},
    {name: 'General Author/SAARC Country/IEEE Member for Poster', amount: 125, short_code: 'USD', type: 'Poster'},
    {name: 'General Author/Other Countries for Poster',amount: 175, short_code: 'USD', type: 'Poster'},
    {name: 'General Author/Other Countries/IEEE Member for Poster',amount: 155, short_code: 'USD', type: 'Poster'},

    {name: 'Student  Author/Local for Poster', amount: 5500, short_code: 'BDT', type: 'Poster'},
    {name: 'Student  Author/Local/ IEEE Member for Poster', amount: 5000, short_code: 'BDT', type: 'Poster'},
    {name: 'Student  Author/SAARC Country for Poster', amount: 140, short_code: 'USD', type: 'Poster'},
    {name: 'Student  Author/SAARC Country/IEEE Member for Poster', amount: 110, short_code: 'USD', type: 'Poster'},
    {name: 'Student  Author/Other Countries for Poster',amount: 170, short_code: 'USD', type: 'Poster'},
    {name: 'Student  Author/Other Countries/IEEE Member for Poster',amount: 155, short_code: 'USD', type: 'Poster'},

]


export const countriesData=[
  {
    "value": "Afghanistan",
    "label": "Afghanistan"
  },
  {
    "value": "Åland Islands",
    "label": "Åland Islands"
  },
  {
    "value": "Albania",
    "label": "Albania"
  },
  {
    "value": "Algeria",
    "label": "Algeria"
  },
  {
    "value": "American Samoa",
    "label": "American Samoa"
  },
  {
    "value": "Andorra",
    "label": "Andorra"
  },
  {
    "value": "Angola",
    "label": "Angola"
  },
  {
    "value": "Anguilla",
    "label": "Anguilla"
  },
  {
    "value": "Antarctica",
    "label": "Antarctica"
  },
  {
    "value": "Antigua and Barbuda",
    "label": "Antigua and Barbuda"
  },
  {
    "value": "Argentina",
    "label": "Argentina"
  },
  {
    "value": "Armenia",
    "label": "Armenia"
  },
  {
    "value": "Aruba",
    "label": "Aruba"
  },
  {
    "value": "Australia",
    "label": "Australia"
  },
  {
    "value": "Austria",
    "label": "Austria"
  },
  {
    "value": "Azerbaijan",
    "label": "Azerbaijan"
  },
  {
    "value": "Bahamas",
    "label": "Bahamas"
  },
  {
    "value": "Bahrain",
    "label": "Bahrain"
  },
  {
    "value": "Bangladesh",
    "label": "Bangladesh"
  },
  {
    "value": "Barbados",
    "label": "Barbados"
  },
  {
    "value": "Belarus",
    "label": "Belarus"
  },
  {
    "value": "Belgium",
    "label": "Belgium"
  },
  {
    "value": "Belize",
    "label": "Belize"
  },
  {
    "value": "Benin",
    "label": "Benin"
  },
  {
    "value": "Bermuda",
    "label": "Bermuda"
  },
  {
    "value": "Bhutan",
    "label": "Bhutan"
  },
  {
    "value": "Bolivia",
    "label": "Bolivia"
  },
  {
    "value": "Bosnia and Herzegovina",
    "label": "Bosnia and Herzegovina"
  },
  {
    "value": "Botswana",
    "label": "Botswana"
  },
  {
    "value": "Bouvet Island",
    "label": "Bouvet Island"
  },
  {
    "value": "Brazil",
    "label": "Brazil"
  },
  {
    "value": "British Indian Ocean Territory",
    "label": "British Indian Ocean Territory"
  },
  {
    "value": "British Virgin Islands",
    "label": "British Virgin Islands"
  },
  {
    "value": "Brunei",
    "label": "Brunei"
  },
  {
    "value": "Bulgaria",
    "label": "Bulgaria"
  },
  {
    "value": "Burkina Faso",
    "label": "Burkina Faso"
  },
  {
    "value": "Burundi",
    "label": "Burundi"
  },
  {
    "value": "Cambodia",
    "label": "Cambodia"
  },
  {
    "value": "Cameroon",
    "label": "Cameroon"
  },
  {
    "value": "Canada",
    "label": "Canada"
  },
  {
    "value": "Cape Verde",
    "label": "Cape Verde"
  },
  {
    "value": "Caribbean Netherlands",
    "label": "Caribbean Netherlands"
  },
  {
    "value": "Cayman Islands",
    "label": "Cayman Islands"
  },
  {
    "value": "Central African Republic",
    "label": "Central African Republic"
  },
  {
    "value": "Chad",
    "label": "Chad"
  },
  {
    "value": "Chile",
    "label": "Chile"
  },
  {
    "value": "China",
    "label": "China"
  },
  {
    "value": "Christmas Island",
    "label": "Christmas Island"
  },
  {
    "value": "Cocos (Keeling) Islands",
    "label": "Cocos (Keeling) Islands"
  },
  {
    "value": "Colombia",
    "label": "Colombia"
  },
  {
    "value": "Comoros",
    "label": "Comoros"
  },
  {
    "value": "Cook Islands",
    "label": "Cook Islands"
  },
  {
    "value": "Costa Rica",
    "label": "Costa Rica"
  },
  {
    "value": "Croatia",
    "label": "Croatia"
  },
  {
    "value": "Cuba",
    "label": "Cuba"
  },
  {
    "value": "Curaçao",
    "label": "Curaçao"
  },
  {
    "value": "Cyprus",
    "label": "Cyprus"
  },
  {
    "value": "Czechia",
    "label": "Czechia"
  },
  {
    "value": "Denmark",
    "label": "Denmark"
  },
  {
    "value": "Djibouti",
    "label": "Djibouti"
  },
  {
    "value": "Dominica",
    "label": "Dominica"
  },
  {
    "value": "Dominican Republic",
    "label": "Dominican Republic"
  },
  {
    "value": "DR Congo",
    "label": "DR Congo"
  },
  {
    "value": "Ecuador",
    "label": "Ecuador"
  },
  {
    "value": "Egypt",
    "label": "Egypt"
  },
  {
    "value": "El Salvador",
    "label": "El Salvador"
  },
  {
    "value": "Equatorial Guinea",
    "label": "Equatorial Guinea"
  },
  {
    "value": "Eritrea",
    "label": "Eritrea"
  },
  {
    "value": "Estonia",
    "label": "Estonia"
  },
  {
    "value": "Eswatini",
    "label": "Eswatini"
  },
  {
    "value": "Ethiopia",
    "label": "Ethiopia"
  },
  {
    "value": "Falkland Islands",
    "label": "Falkland Islands"
  },
  {
    "value": "Faroe Islands",
    "label": "Faroe Islands"
  },
  {
    "value": "Fiji",
    "label": "Fiji"
  },
  {
    "value": "Finland",
    "label": "Finland"
  },
  {
    "value": "France",
    "label": "France"
  },
  {
    "value": "French Guiana",
    "label": "French Guiana"
  },
  {
    "value": "French Polynesia",
    "label": "French Polynesia"
  },
  {
    "value": "French Southern and Antarctic Lands",
    "label": "French Southern and Antarctic Lands"
  },
  {
    "value": "Gabon",
    "label": "Gabon"
  },
  {
    "value": "Gambia",
    "label": "Gambia"
  },
  {
    "value": "Georgia",
    "label": "Georgia"
  },
  {
    "value": "Germany",
    "label": "Germany"
  },
  {
    "value": "Ghana",
    "label": "Ghana"
  },
  {
    "value": "Gibraltar",
    "label": "Gibraltar"
  },
  {
    "value": "Greece",
    "label": "Greece"
  },
  {
    "value": "Greenland",
    "label": "Greenland"
  },
  {
    "value": "Grenada",
    "label": "Grenada"
  },
  {
    "value": "Guadeloupe",
    "label": "Guadeloupe"
  },
  {
    "value": "Guam",
    "label": "Guam"
  },
  {
    "value": "Guatemala",
    "label": "Guatemala"
  },
  {
    "value": "Guernsey",
    "label": "Guernsey"
  },
  {
    "value": "Guinea",
    "label": "Guinea"
  },
  {
    "value": "Guinea-Bissau",
    "label": "Guinea-Bissau"
  },
  {
    "value": "Guyana",
    "label": "Guyana"
  },
  {
    "value": "Haiti",
    "label": "Haiti"
  },
  {
    "value": "Heard Island and McDonald Islands",
    "label": "Heard Island and McDonald Islands"
  },
  {
    "value": "Honduras",
    "label": "Honduras"
  },
  {
    "value": "Hong Kong",
    "label": "Hong Kong"
  },
  {
    "value": "Hungary",
    "label": "Hungary"
  },
  {
    "value": "Iceland",
    "label": "Iceland"
  },
  {
    "value": "India",
    "label": "India"
  },
  {
    "value": "Indonesia",
    "label": "Indonesia"
  },
  {
    "value": "Iran",
    "label": "Iran"
  },
  {
    "value": "Iraq",
    "label": "Iraq"
  },
  {
    "value": "Ireland",
    "label": "Ireland"
  },
  {
    "value": "Isle of Man",
    "label": "Isle of Man"
  },
  {
    "value": "Israel",
    "label": "Israel"
  },
  {
    "value": "Italy",
    "label": "Italy"
  },
  {
    "value": "Ivory Coast",
    "label": "Ivory Coast"
  },
  {
    "value": "Jamaica",
    "label": "Jamaica"
  },
  {
    "value": "Japan",
    "label": "Japan"
  },
  {
    "value": "Jersey",
    "label": "Jersey"
  },
  {
    "value": "Jordan",
    "label": "Jordan"
  },
  {
    "value": "Kazakhstan",
    "label": "Kazakhstan"
  },
  {
    "value": "Kenya",
    "label": "Kenya"
  },
  {
    "value": "Kiribati",
    "label": "Kiribati"
  },
  {
    "value": "Kosovo",
    "label": "Kosovo"
  },
  {
    "value": "Kuwait",
    "label": "Kuwait"
  },
  {
    "value": "Kyrgyzstan",
    "label": "Kyrgyzstan"
  },
  {
    "value": "Laos",
    "label": "Laos"
  },
  {
    "value": "Latvia",
    "label": "Latvia"
  },
  {
    "value": "Lebanon",
    "label": "Lebanon"
  },
  {
    "value": "Lesotho",
    "label": "Lesotho"
  },
  {
    "value": "Liberia",
    "label": "Liberia"
  },
  {
    "value": "Libya",
    "label": "Libya"
  },
  {
    "value": "Liechtenstein",
    "label": "Liechtenstein"
  },
  {
    "value": "Lithuania",
    "label": "Lithuania"
  },
  {
    "value": "Luxembourg",
    "label": "Luxembourg"
  },
  {
    "value": "Macau",
    "label": "Macau"
  },
  {
    "value": "Madagascar",
    "label": "Madagascar"
  },
  {
    "value": "Malawi",
    "label": "Malawi"
  },
  {
    "value": "Malaysia",
    "label": "Malaysia"
  },
  {
    "value": "Maldives",
    "label": "Maldives"
  },
  {
    "value": "Mali",
    "label": "Mali"
  },
  {
    "value": "Malta",
    "label": "Malta"
  },
  {
    "value": "Marshall Islands",
    "label": "Marshall Islands"
  },
  {
    "value": "Martinique",
    "label": "Martinique"
  },
  {
    "value": "Mauritania",
    "label": "Mauritania"
  },
  {
    "value": "Mauritius",
    "label": "Mauritius"
  },
  {
    "value": "Mayotte",
    "label": "Mayotte"
  },
  {
    "value": "Mexico",
    "label": "Mexico"
  },
  {
    "value": "Micronesia",
    "label": "Micronesia"
  },
  {
    "value": "Moldova",
    "label": "Moldova"
  },
  {
    "value": "Monaco",
    "label": "Monaco"
  },
  {
    "value": "Mongolia",
    "label": "Mongolia"
  },
  {
    "value": "Montenegro",
    "label": "Montenegro"
  },
  {
    "value": "Montserrat",
    "label": "Montserrat"
  },
  {
    "value": "Morocco",
    "label": "Morocco"
  },
  {
    "value": "Mozambique",
    "label": "Mozambique"
  },
  {
    "value": "Myanmar",
    "label": "Myanmar"
  },
  {
    "value": "Namibia",
    "label": "Namibia"
  },
  {
    "value": "Nauru",
    "label": "Nauru"
  },
  {
    "value": "Nepal",
    "label": "Nepal"
  },
  {
    "value": "Netherlands",
    "label": "Netherlands"
  },
  {
    "value": "New Caledonia",
    "label": "New Caledonia"
  },
  {
    "value": "New Zealand",
    "label": "New Zealand"
  },
  {
    "value": "Nicaragua",
    "label": "Nicaragua"
  },
  {
    "value": "Niger",
    "label": "Niger"
  },
  {
    "value": "Nigeria",
    "label": "Nigeria"
  },
  {
    "value": "Niue",
    "label": "Niue"
  },
  {
    "value": "Norfolk Island",
    "label": "Norfolk Island"
  },
  {
    "value": "North Korea",
    "label": "North Korea"
  },
  {
    "value": "North Macedonia",
    "label": "North Macedonia"
  },
  {
    "value": "Northern Mariana Islands",
    "label": "Northern Mariana Islands"
  },
  {
    "value": "Norway",
    "label": "Norway"
  },
  {
    "value": "Oman",
    "label": "Oman"
  },
  {
    "value": "Pakistan",
    "label": "Pakistan"
  },
  {
    "value": "Palau",
    "label": "Palau"
  },
  {
    "value": "Palestine",
    "label": "Palestine"
  },
  {
    "value": "Panama",
    "label": "Panama"
  },
  {
    "value": "Papua New Guinea",
    "label": "Papua New Guinea"
  },
  {
    "value": "Paraguay",
    "label": "Paraguay"
  },
  {
    "value": "Peru",
    "label": "Peru"
  },
  {
    "value": "Philippines",
    "label": "Philippines"
  },
  {
    "value": "Pitcairn Islands",
    "label": "Pitcairn Islands"
  },
  {
    "value": "Poland",
    "label": "Poland"
  },
  {
    "value": "Portugal",
    "label": "Portugal"
  },
  {
    "value": "Puerto Rico",
    "label": "Puerto Rico"
  },
  {
    "value": "Qatar",
    "label": "Qatar"
  },
  {
    "value": "Republic of the Congo",
    "label": "Republic of the Congo"
  },
  {
    "value": "Réunion",
    "label": "Réunion"
  },
  {
    "value": "Romania",
    "label": "Romania"
  },
  {
    "value": "Russia",
    "label": "Russia"
  },
  {
    "value": "Rwanda",
    "label": "Rwanda"
  },
  {
    "value": "Saint Barthélemy",
    "label": "Saint Barthélemy"
  },
  {
    "value": "Saint Helena, Ascension and Tristan da Cunha",
    "label": "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    "value": "Saint Kitts and Nevis",
    "label": "Saint Kitts and Nevis"
  },
  {
    "value": "Saint Lucia",
    "label": "Saint Lucia"
  },
  {
    "value": "Saint Martin",
    "label": "Saint Martin"
  },
  {
    "value": "Saint Pierre and Miquelon",
    "label": "Saint Pierre and Miquelon"
  },
  {
    "value": "Saint Vincent and the Grenadines",
    "label": "Saint Vincent and the Grenadines"
  },
  {
    "value": "Samoa",
    "label": "Samoa"
  },
  {
    "value": "San Marino",
    "label": "San Marino"
  },
  {
    "value": "São Tomé and Príncipe",
    "label": "São Tomé and Príncipe"
  },
  {
    "value": "Saudi Arabia",
    "label": "Saudi Arabia"
  },
  {
    "value": "Senegal",
    "label": "Senegal"
  },
  {
    "value": "Serbia",
    "label": "Serbia"
  },
  {
    "value": "Seychelles",
    "label": "Seychelles"
  },
  {
    "value": "Sierra Leone",
    "label": "Sierra Leone"
  },
  {
    "value": "Singapore",
    "label": "Singapore"
  },
  {
    "value": "Sint Maarten",
    "label": "Sint Maarten"
  },
  {
    "value": "Slovakia",
    "label": "Slovakia"
  },
  {
    "value": "Slovenia",
    "label": "Slovenia"
  },
  {
    "value": "Solomon Islands",
    "label": "Solomon Islands"
  },
  {
    "value": "Somalia",
    "label": "Somalia"
  },
  {
    "value": "South Africa",
    "label": "South Africa"
  },
  {
    "value": "South Georgia",
    "label": "South Georgia"
  },
  {
    "value": "South Korea",
    "label": "South Korea"
  },
  {
    "value": "South Sudan",
    "label": "South Sudan"
  },
  {
    "value": "Spain",
    "label": "Spain"
  },
  {
    "value": "Sri Lanka",
    "label": "Sri Lanka"
  },
  {
    "value": "Sudan",
    "label": "Sudan"
  },
  {
    "value": "Suriname",
    "label": "Suriname"
  },
  {
    "value": "Svalbard and Jan Mayen",
    "label": "Svalbard and Jan Mayen"
  },
  {
    "value": "Sweden",
    "label": "Sweden"
  },
  {
    "value": "Switzerland",
    "label": "Switzerland"
  },
  {
    "value": "Syria",
    "label": "Syria"
  },
  {
    "value": "Taiwan",
    "label": "Taiwan"
  },
  {
    "value": "Tajikistan",
    "label": "Tajikistan"
  },
  {
    "value": "Tanzania",
    "label": "Tanzania"
  },
  {
    "value": "Thailand",
    "label": "Thailand"
  },
  {
    "value": "Timor-Leste",
    "label": "Timor-Leste"
  },
  {
    "value": "Togo",
    "label": "Togo"
  },
  {
    "value": "Tokelau",
    "label": "Tokelau"
  },
  {
    "value": "Tonga",
    "label": "Tonga"
  },
  {
    "value": "Trinidad and Tobago",
    "label": "Trinidad and Tobago"
  },
  {
    "value": "Tunisia",
    "label": "Tunisia"
  },
  {
    "value": "Turkey",
    "label": "Turkey"
  },
  {
    "value": "Turkmenistan",
    "label": "Turkmenistan"
  },
  {
    "value": "Turks and Caicos Islands",
    "label": "Turks and Caicos Islands"
  },
  {
    "value": "Tuvalu",
    "label": "Tuvalu"
  },
  {
    "value": "Uganda",
    "label": "Uganda"
  },
  {
    "value": "Ukraine",
    "label": "Ukraine"
  },
  {
    "value": "United Arab Emirates",
    "label": "United Arab Emirates"
  },
  {
    "value": "United Kingdom",
    "label": "United Kingdom"
  },
  {
    "value": "United States",
    "label": "United States"
  },
  {
    "value": "United States Minor Outlying Islands",
    "label": "United States Minor Outlying Islands"
  },
  {
    "value": "United States Virgin Islands",
    "label": "United States Virgin Islands"
  },
  {
    "value": "Uruguay",
    "label": "Uruguay"
  },
  {
    "value": "Uzbekistan",
    "label": "Uzbekistan"
  },
  {
    "value": "Vanuatu",
    "label": "Vanuatu"
  },
  {
    "value": "Vatican City",
    "label": "Vatican City"
  },
  {
    "value": "Venezuela",
    "label": "Venezuela"
  },
  {
    "value": "Vietnam",
    "label": "Vietnam"
  },
  {
    "value": "Wallis and Futuna",
    "label": "Wallis and Futuna"
  },
  {
    "value": "Western Sahara",
    "label": "Western Sahara"
  },
  {
    "value": "Yemen",
    "label": "Yemen"
  },
  {
    "value": "Zambia",
    "label": "Zambia"
  },
  {
    "value": "Zimbabwe",
    "label": "Zimbabwe"
  }
]