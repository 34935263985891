import React from 'react';
import {Badge, Card, Container, ListGroup} from "react-bootstrap";
import './about.css';
import Proconf from "../../components/Proconf/Proconf";
import config from "../../config";
import {track, dateData} from "../../components/data/data";
import IEEE from "../../components/assets/img/IEEE_logo1.jpg";
import IEEE_CS_Logo from "../../components/assets/img/IEEE-CS_Logo1.jpg";
// import IEEE_BD from "../../components/assets/img/IEEE_BD1.jpg";
import IEEE_BD from "../../components/assets/img/ieee_bd2.jpg";

const About = () => {
    return (
        <Container fluid className="mb-3">
            <Container className="mt-1">
                <div className='row'>
                    <div className="col-md-8">
                        <h2 className="pt-2 title">About NCIM 2023</h2>
                        <p className="text-justify card_text">

                            <b> International Conference on Next-Generation Computing, IoT and Machine Learning (NCIM
                                2023) </b> is going to be organized by Department of Computer Science and Engineering of
                            Dhaka University of Engineering &amp; Technology, Gazipur, Bangladesh on June 16-17, 2023.
                            In the areas of Computing, Internet
                            of Things, Artificial intelligence, Computer Vision, and Related new technologies. NCIM 2023
                            focuses on theoretical, practical, cutting-edge applications, and research issues.
                            The conference aims to bring together researchers, academicians, industry professionals,
                            and experts from a variety of disciplines to present contemporary research findings obtained
                            through the use of next-generation technologies. <b className="animated-text"> All accepted
                            and presented papers will be submitted for inclusion into IEEE Xplore subject to
                            meeting IEEE Xplore’s scope and quality requirements. IEEE Technical Co-sponsorship for NCIM 2023 has been approved on 23 May, 2023 (Conference Id: 59001)</b>.
                            Researchers of the relevant
                            fields are invited to submit their original, novel, and extended unpublished works in this
                            conference. In NCIM
                            2023, both onsite presentations and virtual participations (from abroad only) will be
                            allowed.

                        </p>
                        <h2 className="pt-4 title">About DUET</h2>
                        <p className="text-justify card_text">
                            Dhaka University of Engineering & Technology (DUET), Gazipur was founded as the College of
                            Engineering, Dhaka in 1980. It was then renamed as Dhaka Engineering College (DEC) under the
                            University of Dhaka in 1981.
                            Thereupon, DEC was empowered autonomy under the Bangladesh Institute of Technology ordinance
                            no. XXI of 1986, under the name
                            Bangladesh Institute of Technology, Dhaka (Abbreviated as BITD). Finally on September 1,
                            2003 the Institute
                            became an autonomous statutory organization of Government of the People's Republic of
                            Bangladesh under the
                            Dhaka University of Engineering and Technology, Gazipur Act 2003. The University campus is
                            in Gazipur (40 km
                            north of Dhaka, the capital of Bangladesh), with convenient access to the Hazrat Shahjalal
                            International Airport,
                            Gazipur Railway Station, and Gazipur City Bus Terminal.
                        </p>
                        <h2 className="pt-4 title">Technical Tracks and Scopes</h2>
                        <p className="text-justify card_text">

                            We are glad to inform you that our advisory panel, technical program committee, and
                            organizing
                            committee at NCIM 2023 include prominent scholars from both home and abroad. NCIM 2023 will
                            feature prominent keynote speakers, invited speakers, and resource persons. We invite
                            academic members
                            and researchers from all around the world to participate in this fantastic international
                            event. Papers
                            presenting original research are being sought within following tracks, but not limited to:

                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="row mt-4">

                            <div className="col-md-6 ">
                                <img src={IEEE} className="img-fluid " alt="Responsive image"/>
                            </div>

                            <div className="col-md-6 text-center">
                                <img src={IEEE_CS_Logo} className="img-fluid " alt="Responsive image"/>
                            </div>
                            {/*<div className="row justify-content-center mt-2">*/}
                            {/*    <div className="col-md-6 text-center">*/}
                            {/*        <img src={IEEE_BD} className="img-fluid o" alt="Responsive image"/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>
                        <div className="mt-2">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="100%" height="250" src="https://ieeetv.ieee.org/ns/ieeetvdl/2023/CA-2023-not-for-dist/saifur-social/Saifur-Rahman_IEEE-NCIM_2023-message.mp4?fbclid=IwAR3ogJOGq0BgbjVphbOIVw3ifOM-RTgyHUJnTQtFjZLJ9d1h4cWOdIWMv9o"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>
                            </div>
                        </div>


                        <h2 className="pt-4 title">IMPORTANT DATES</h2>

                        {/*<p className="d-flex justify-content-center align-items-center text-success fw-bold" >To Be Announced (TBA)</p>*/}

                        <ListGroup as="ul" variant="flush">
                            {dateData.map((item) => (
                                <div key={Math.random()}>

                                    <ListGroup.Item as="li" key={Math.random()}
                                                    className="d-flex justify-content-between align-items-start"
                                                    data-aos="fade-up">

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.heading}</div>
                                            </div>
                                        <div className="d-flex flex-column align-items-end">
                                            <Badge bg={item.color}
                                                   className={["p-2", item.status !== 'Active' ? 'text-decoration-line-through' : ''].join(' ')}
                                                   pill>
                                                {item.dateEvent}
                                            </Badge>

                                            {item.extension  &&
                                                <Badge bg={item.color} className={["p-2", "mt-2", item.heading==='Camera-Ready Submission'? '': 'text-decoration-line-through'].join(' ')} pill>
                                                    {item.extension} <br/>
                                                     (Extended)

                                                </Badge>
                                            }
                                             {item.extension && item.hardexten!=='' &&(
                                                <Badge bg="success" className="p-2 mt-2" pill>
                                                    {item.hardexten} <br/>
                                                     (Hard Deadline)
                                                </Badge>)
                                            }
                                        </div>

                                    </ListGroup.Item>


                                </div>

                            ))}
                        </ListGroup>

                        <div className="mt-5">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="100%" height="250" src="https://www.youtube.com/embed/4C2FFKkU5IQ"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>
                            </div>
                        </div>


                    </div>
                </div>


                <div className="row">
                    <div className="col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Card className="card_shdow h-100">
                            <Card.Body>
                                <Card.Title className='text-muted'> TRACK 1:</Card.Title>
                                <Card.Subtitle className="mb-2 fw-bold">
                                    Computing and Software Engineering
                                </Card.Subtitle>
                                <ol>
                                    {track.track1.map((item) => (
                                        <li key={Math.random()}>{item}</li>
                                    ))}
                                </ol>


                                {/*<ListGroup as="ol" numbered>*/}
                                {/*    {track.track1.map((item)=>(*/}
                                {/*         <ListGroup.Item as="li" key={Math.random()}>{item} </ListGroup.Item>*/}
                                {/*    ))}*/}
                                {/*</ListGroup>*/}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Card className="card_shdow h-100">
                            <Card.Body>
                                <Card.Title className='text-muted'>TRACK 2:</Card.Title>
                                <Card.Subtitle className="mb-2 fw-bold">
                                    Networking and IoT
                                </Card.Subtitle>
                                <ol>
                                    {track.track2.map((item) => (
                                        <li key={Math.random()}>{item}</li>
                                    ))}
                                </ol>
                                {/*<ListGroup as="ol" numbered>*/}
                                {/*    {track.track2.map((item)=>(*/}
                                {/*         <ListGroup.Item as="li" key={Math.random()}>{item} </ListGroup.Item>*/}
                                {/*    ))}*/}

                                {/*</ListGroup>*/}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Card className="card_shdow h-100">
                            <Card.Body>
                                <Card.Title className='text-muted'>TRACK 3:</Card.Title>
                                <Card.Subtitle className="mb-2 fw-bold ">
                                    Artificial Intelligence and Machine Learning
                                </Card.Subtitle>
                                <ol>
                                    {track.track3.map((item) => (
                                        <li key={Math.random()}>{item}</li>
                                    ))}
                                </ol>
                                {/*<ListGroup as="ol" numbered>*/}
                                {/*    {track.track3.map((item)=>(*/}
                                {/*         <ListGroup.Item as="li" key={Math.random()}>{item} </ListGroup.Item>*/}
                                {/*    ))}*/}

                                {/*</ListGroup>*/}
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-md-3" data-aos="fade-up">
                        <Card className="card_shdow h-100">
                            <Card.Body>
                                <Card.Title className='text-muted'>TRACK 4:</Card.Title>
                                <Card.Subtitle className="mb-2 fw-bold">
                                    Signal, Image and Computer Vision
                                </Card.Subtitle>
                                <ol>
                                    {track.track4.map((item) => (
                                        <li key={Math.random()}>{item}</li>
                                    ))}
                                </ol>
                                {/*<ListGroup as="ol" numbered>*/}
                                {/*    {track.track4.map((item)=>(*/}
                                {/*         <ListGroup.Item as="li" key={Math.random()}>{item} </ListGroup.Item>*/}
                                {/*    ))}*/}

                                {/*</ListGroup>*/}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        </Container>
    )
}

export default About